import { useEffect, useState } from 'react';
import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';

import { UNDEFINED } from '../../consts/CommonConstants';
import styles from './Input.module.css';

const Input = ({
  shouldDisableKeyDown,
  inputType,
  min,
  maxlength,
  required,
  defaultValue,
  placeholder,
  hint,
  error,
  errorMessage,
  errorMessageParams = {},
  callback,
  additionalStyles = {},
}) => {
  // let [isFirst, setIsFirst] = useState(true);

  const [value, setValue] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (defaultValue !== UNDEFINED) {
      handleChange(defaultValue);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getInputClasses = () => {
    let classes = styles.form__field;
    if (additionalStyles.input) classes += ` ${additionalStyles.input}`;
    if (error) classes += ` ${styles.form__field_invalid}`;
    return classes;
  };

  const handleChange = (value) => {
    setValue(value);
    if (callback !== UNDEFINED) callback(value);
  };

  const disableKeyDown = (event) => {
    if (shouldDisableKeyDown) {
      event.preventDefault();
    }
  };

  return (
    <FlexView className={additionalStyles.container} column>
      <FlexView>
        <input
          type={inputType}
          min={min}
          defaultValue={defaultValue}
          maxLength={maxlength}
          onKeyDown={disableKeyDown}
          onChange={(e) => handleChange(e.target.value)}
          className={getInputClasses()}
          placeholder={placeholder}
        />
        {/* {required && <span className={styles.required}>*</span>} */}
      </FlexView>
      {maxlength && !error && (
        <div className={styles.character_count}>
          <span>{value ? value.length : 0}</span>
          <span>/{maxlength}</span>
        </div>
      )}
      <FlexView className={styles.input_helper_text_container} column>
        {error && (
          <span className={styles.input_error_message_span}>
            {t(errorMessage, errorMessageParams)}
          </span>
        )}
        {hint && (
          <FlexView>
            <span className={styles.input_helper_text}>{hint}</span>
          </FlexView>
        )}
      </FlexView>
    </FlexView>
  );
};

export default Input;
