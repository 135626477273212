import PropTypes from 'prop-types';

import styles from './Table.module.css';
import TableHeadItem from './TableHeadItem';
import { RecursiveRow } from './TableRow';

const Table = ({ theadData, tbodyData, customClass }) => {
  return (
    <table className={`${styles.table} ${customClass}`}>
      <thead>
        <tr className={styles.table_tr}>
          {theadData &&
            theadData.map((item, index) => {
              return <TableHeadItem item={item} key={'header_' + item + index} />;
            })}
        </tr>
      </thead>
      <tbody>
        {tbodyData &&
          tbodyData.map((item) => {
            return <RecursiveRow key={'row_' + item.id} rowData={item} columns={theadData} />;
          })}
        <tr></tr> {/*Empty row to maintain height of other rows.*/}
      </tbody>
    </table>
  );
};

Table.propTypes = {
  theadData: PropTypes.array, //Returns an array of items to be displayed as column headers of the table
  tbodyData: PropTypes.array,
};

// Specifies the default values for props:
Table.defaultProps = {
  theadData: [],
  tbodyData: [],
};

export default Table;
