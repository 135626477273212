import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';

import styles from './PrivacyPolicy.module.css';

const VisitingOurWebsite = () => {
  const { t } = useTranslation();

  return (
    <FlexView className={styles.general_container} column>
      <span>{t('privacy.visit-website.text1')}</span>
      <ul>
        <li>{t('privacy.visit-website.text2')}</li>
        <li>{t('privacy.visit-website.text3')}</li>
        <li>{t('privacy.visit-website.text4')}</li>
        <li>{t('privacy.visit-website.text5')}</li>
        <li>{t('privacy.visit-website.text6')}</li>
        <li>{t('privacy.visit-website.text7')}</li>
        <li>{t('privacy.visit-website.text8')}</li>
        <li>{t('privacy.visit-website.text9')}</li>
        <li>{t('privacy.visit-website.text10')}</li>
        <li>{t('privacy.visit-website.text11')}</li>
        <li>{t('privacy.visit-website.text12')}</li>
        <li>{t('privacy.visit-website.text13')}</li>
      </ul>
      <span>{t('privacy.visit-website.text14')}</span>
    </FlexView>
  );
};

export default VisitingOurWebsite;
