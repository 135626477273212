import styles from './RadioButton.module.css';

const RadioButton = ({ checked, name, code, label }) => {
  return (
    <label className={styles.radio_container}>
      <input
        type="radio"
        checked={checked}
        className={styles.radio_input}
        name={name}
        value={code}
      />
      {label}
    </label>
  );
};

export default RadioButton;
