import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HashRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { THEMES } from '../consts/CommonConstants';
import { AD_SALES } from '../consts/Roles';
import ThemeContext from '../contexts/ThemeContext';
import CreatedInvitations from '../CreatedInvitations/CreatedInvitations';
import CreateInvitations from '../CreateInvitations/CreateInvitations';
import LegalDisclosure from '../PrivacyPolicy/LegalDisclosure/LegalDisclosure';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import Products from '../Products/Products';
import Profile from '../Profile/Profile';
import CompanyDetails from '../Sales/CompanyDetails/CompanyDetails';
import CompanyList from '../Sales/CompanyList/CompanyList';
import CompanyPlans from '../Sales/CompanyPlans/CompanyPlans';
import NewPlan from '../Sales/NewPlan/NewPlan';
import UserDetails from '../Sales/UserDetails/UserDetails';
import Users from '../Sales/Users/Users';
import { authService } from '../services/AuthService';
import browserIdleWatcher from '../services/browserIdleWatcher';
import userProfileService from '../services/UserProfileService';
import HttpGateway from '../Shared/HttpGateway';
import TeamAssessment from '../TeamAssessments/TeamAssessment';
import UserManuals from '../UserManuals/UserManuals';
import Contact from './Contact/Contact';
import Dashboard from './Dashboard';
import LoginComponent from './LoginComponent';
import PageNotFound from './PageNotFound';
import ToBeImplemented from './ToBeImplemented';

/** If the user is authenticated, allow the children to be rendered  */
const Private = ({ children }) => {
  const isAuthenticated = authService.isAuthenticated();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (!isAuthenticated) {
      try {
        let browserLocale = window.navigator.language;
        sessionStorage.setItem('user-locale', browserLocale);
        i18n.changeLanguage(browserLocale);
      } catch (err) {
        console.log(err);
      }
      navigate('/login');
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return isAuthenticated ? children : null;
};

const RouteResolveComp = () => {
  const [theme, setTheme] = useState(THEMES.EXTERNAL);
  const value = { theme, setTheme };
  const { i18n } = useTranslation();
  const isAuthenticated = authService.isAuthenticated();
  var isInternalUser = userProfileService.getCurrentRole() === AD_SALES;

  useEffect(() => {
    if (authService.isAuthenticated()) {
      browserIdleWatcher();
      HttpGateway.setToken();
      isInternalUser = userProfileService.getCurrentRole() === AD_SALES;
      updateTheme();
      let userPreferredLocale = userProfileService.getUserPreferredLocale();
      if (userPreferredLocale) {
        console.log('user prefered lang', userPreferredLocale);
        sessionStorage.setItem('user-locale', userPreferredLocale);
        i18n.changeLanguage(userPreferredLocale);
      }
    } else {
      try {
        let browserLocale = window.navigator.language;
        sessionStorage.setItem('user-locale', browserLocale);
        i18n.changeLanguage(browserLocale);
      } catch (err) {
        console.log(err);
      }
    }
  }, [theme]);

  const updateTheme = () => {
    const userProfile = userProfileService.getUserProfile();
    if (userProfile) {
      isInternalUser ? setTheme(THEMES.INTERNAL) : setTheme(THEMES.EXTERNAL);
    }
  };

  return (
    <ThemeContext.Provider value={value}>
      <HashRouter>
        <Routes>
          <Route
            exact
            path="/"
            element={
              isAuthenticated ? (
                <Navigate to={isInternalUser ? '/companylist' : '/dashboard'} replace />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            exact
            path="/login"
            element={
              isAuthenticated ? (
                <Navigate to={isInternalUser ? '/companylist' : '/dashboard'} replace />
              ) : (
                <LoginComponent />
              )
            }
          />
          <Route
            exact
            path="/dashboard"
            element={
              <Private>
                <Dashboard />
              </Private>
            }
          />
          <Route
            exact
            path="/contact"
            element={
              <Private>
                <Contact />
              </Private>
            }
          />
          <Route
            exact
            path="/usermanuals"
            element={
              <Private>
                <UserManuals />
              </Private>
            }
          />
          <Route
            exact
            path="/profile"
            element={
              <Private>
                <Profile />
              </Private>
            }
          />
          <Route
            exact
            path="/createinvitations"
            element={
              <Private>
                <CreateInvitations />
              </Private>
            }
          />
          <Route
            exact
            path="/createdinvitations"
            element={
              <Private>
                <CreatedInvitations />
              </Private>
            }
          />
          <Route
            exact
            path="/teamAssessment"
            element={
              <Private>
                <TeamAssessment />
              </Private>
            }
          />
          <Route
            exact
            path="/tobeimplemented"
            element={
              <Private>
                <ToBeImplemented />
              </Private>
            }
          />
          <Route
            exact
            path="/companylist"
            element={
              <Private>
                <CompanyList />
              </Private>
            }
          />
          <Route
            exact
            path="/companydetails"
            element={
              <Private>
                <CompanyDetails />
              </Private>
            }
          />
          <Route
            exact
            path="/plans"
            element={
              <Private>
                <CompanyPlans />
              </Private>
            }
          />
          <Route
            exact
            path="/users"
            element={
              <Private>
                <Users />
              </Private>
            }
          />
          <Route
            exact
            path="/userdetails"
            element={
              <Private>
                <UserDetails />
              </Private>
            }
          />
          <Route
            exact
            path="/products"
            element={
              <Private>
                <Products />
              </Private>
            }
          />
          <Route
            exact
            path="/newplan"
            element={
              <Private>
                <NewPlan />
              </Private>
            }
          />
          <Route exact path="/:lang?/privacypolicy" element={<PrivacyPolicy />} />
          <Route exact path="/:lang?/legaldisclosure" element={<LegalDisclosure />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </HashRouter>
    </ThemeContext.Provider>
  );
};

export default RouteResolveComp;
