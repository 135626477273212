import { createReducer } from '@reduxjs/toolkit';

import { setUserListContext } from '../actions/UserActions';

const initialState = {};

export const userListReducer = createReducer(initialState, (builder) => {
  builder.addCase(setUserListContext, (state, action) => {
    state.context = action.payload;
  });
});
