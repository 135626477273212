import { useEffect, useState } from 'react';
import FlexView from 'react-flexview';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import DomRender from '../../components/DomRender';
import Modal from '../../components/UI/Modal';
import failureIcon from '../../images/important_note.svg';
import successTickInternal from '../../images/success_tick_internal.svg';
import httpMessenger from '../../services/HTTPMessenger';
import Spinner from '../../Shared/Spinner';
import validationService from '../../Shared/ValidationService';
import styles from './CreateCompany.module.css';

const formFields = [
  {
    label: 'Company name',
    code: 'companyName',
    type: 'text',
    placeholder: 'Enter a company name',
    style: {
      container: styles.form_field_additional_style,
    },
    instantValidations: [
      {
        type: 'required',
        attributes: {},
      },
    ],
  },
  {
    label: 'Invoicing company',
    code: 'invoicingCompanyName',
    type: 'text',
    placeholder: 'Invoicing company name',
    hint: "The invoicing company's name should be an identical match to the name in HubSpot.",
    style: {
      container: styles.form_field_additional_style,
    },
    instantValidations: [
      {
        type: 'required',
        attributes: {},
      },
    ],
  },
];

function CreateCompany(props) {
  const { t } = useTranslation();
  const [payLoad, setPayLoad] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const [error, setError] = useState({});
  const [errorMessage, setErrorMessage] = useState({});
  const navigate = useNavigate();

  const handleSubmit = () => {
    let isValid;
    for (let data of formFields) {
      let instantValidationsChecked = handleValidations(
        data.instantValidations,
        data.code,
        payLoad[data.code],
      );
      if (instantValidationsChecked) {
        let submitValidationsChecked = handleValidations(
          data.submitValidations,
          data.code,
          payLoad[data.code],
        );
        if (submitValidationsChecked && isValid !== false) isValid = true;
        else {
          isValid = false;
        }
      } else isValid = false;
    }
    if (isValid) createCompany();
  };

  const closeDialog = (resultIfSuccess) => {
    setPayLoad({});
    setError({});
    setErrorMessage({});
    modalClose();
    props.modalClose();
    if (resultIfSuccess !== undefined) {
      const result = resultIfSuccess;
      navigate(`/companydetails?companyId=${result.id}`);
    }
  };

  const getElementProps = (data) => {
    let updatedProps = {};
    updatedProps.placeholder = data.placeholder;
    updatedProps.additionalStyles = { ...data.style };
    updatedProps.required =
      (data.submitValidations &&
        data.submitValidations.some((validation) => validation.type === 'required')) ||
      (data.instantValidations &&
        data.instantValidations.some((validation) => validation.type === 'required'));
    updatedProps.error = error[data.code];
    updatedProps.errorMessage = errorMessage[data.code];
    updatedProps.defaultValue = payLoad[data.code];
    return updatedProps;
  };

  const createCompany = async () => {
    const body = {
      companyName: payLoad.companyName,
      invoicingCompanyName: payLoad.invoicingCompanyName,
    };
    setisLoading(true);
    const httpResult = await httpMessenger.apiCall('POST', 'createCompany', body);
    setisLoading(false);

    if (httpResult.success) {
      showDialog(true, httpResult.result);
    } else {
      let errorMessage = [];
      httpResult.error.forEach((error) => {
        errorMessage.push(t(error.key, error.parameters));
      });
      showDialog(false, errorMessage.join(' '));
    }
  };

  const showDialog = (success, resultOrErrorMessage) => {
    setModalInfo({
      show: true,
      showMessage: true,
      text: success
        ? 'A new company was successfully added. Check out the company details.'
        : resultOrErrorMessage,
      formActions: {
        rightButton: {
          text: 'Close',
          action: success ? () => closeDialog(resultOrErrorMessage) : showForm,
        },
      },
      icon: success ? successTickInternal : failureIcon,
    });
  };

  const formActions = {
    rightButton: {
      text: 'Submit',
      action: handleSubmit,
      style: styles.modal_submit_button_style,
    },
    leftButton: {
      text: 'Cancel',
      action: () => closeDialog(),
      style: styles.modal_cancel_button_style,
    },
  };

  const [modalInfo, setModalInfo] = useState({
    show: false,
    formActions,
  });

  const handleChange = (data, value) => {
    setPayLoad((prevPayload) => ({ ...prevPayload, [data.code]: value }));
    if (data.instantValidations) {
      handleValidations(data.instantValidations, data.code, value);
    } else {
      setError((prevErrorObj) => ({ ...prevErrorObj, [data.code]: false }));
      setErrorMessage((prevErrorMessageObj) => ({ ...prevErrorMessageObj, [data.code]: '' }));
    }
  };

  const handleValidations = (validations, code, validateValue) => {
    let isValid, message;
    if (validations) {
      for (let validation of validations) {
        [isValid, message] = [
          ...validationService.validate(validation.type, validateValue, validation.attributes),
        ];
        if (!isValid) {
          setError((prevErrorObj) => ({ ...prevErrorObj, [code]: true }));
          setErrorMessage((prevErrorMessageObj) => ({ ...prevErrorMessageObj, [code]: message }));
          break;
        } else {
          setError((prevErrorObj) => ({ ...prevErrorObj, [code]: false }));
          setErrorMessage((prevErrorMessageObj) => ({ ...prevErrorMessageObj, [code]: message }));
        }
      }
      return isValid ? true : false;
    } else return true;
  };

  const modalClose = () => {
    setModalInfo({
      show: false,
      showMessage: false,
    });
  };

  const showForm = () => {
    setModalInfo({
      show: props.modalOpen,
      formActions,
    });
  };

  useEffect(() => {
    setModalInfo({
      show: props.modalOpen,
      formActions,
    });
  }, [props.modalOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    /* This does not change the value of modalInfo, but updates the values inside
    the function calls handleSubmit and closeDialog to hold the latest
    values of the useState variable (payload) */
    if (modalInfo.show)
      setModalInfo((modalInfo) => ({ ...modalInfo, ...{ formActions: formActions } }));
  }, [payLoad]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {modalInfo.show && !modalInfo.showMessage && (
        <Modal
          headerText={'Add a new company'}
          onClose={() => closeDialog()}
          formActions={modalInfo.formActions}
        >
          <FlexView column className={styles.modal_content_container}>
            {formFields.map((data, index) => {
              return (
                <FlexView className={styles.form_element_container} key={index}>
                  <span className={styles.form_element_label}>{data.label}</span>
                  <FlexView className={styles.form_element_type_container}>
                    <DomRender
                      elemType={data.type}
                      {...getElementProps(data)}
                      callback={(value) => handleChange(data, value)}
                    />
                  </FlexView>
                </FlexView>
              );
            })}
          </FlexView>
        </Modal>
      )}
      {modalInfo.show && modalInfo.showMessage && (
        <Modal onClose={() => closeDialog()} formActions={modalInfo.formActions}>
          <FlexView column className={styles.modal_message_content_container}>
            <img src={modalInfo.icon} className={styles.modal_content_icon} alt="modal_icon" />
            <span className={styles.modal_content_text}>{modalInfo.text}</span>
          </FlexView>
        </Modal>
      )}
      {isLoading && <Spinner />}
    </>
  );
}

export default CreateCompany;
