import { useEffect, useState } from 'react';
import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setInvitationsContext } from '../actions/InvitationsActions';
import httpMessenger from '../services/HTTPMessenger';
import userProfileService from '../services/UserProfileService';
import localStorageService from '../Shared/LocalStorageService';
import Spinner from '../Shared/Spinner';
import styles from '../styles/Dashboard.module.css';
import EmptyPage from './EmptyPage';
import Footer from './Footer';
import Header from './Header';
import PageLayout from './PageLayout';
import Banner from './UI/Banner';
import Table from './UI/Table';

const Dashboard = () => {
  const { t } = useTranslation();
  const [dashboardList, setdashboardList] = useState([]);
  const [availableUnits, setAvailableUnits] = useState(null);
  const [isUnitsLow, setIsUnitsLow] = useState(false);
  const [showWarningBanner, setShowWarningBanner] = useState(false);
  const [unitsLowMessage, setUnitsLowMessage] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [emptyMessage, setEmptyMessage] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const ColumnHeaders = [
    { title: '' },
    { title: t('user.dashboard.product-group') },
    { title: t('user.dashboard.active-invitations') },
    {
      title: t('user.dashboard.price'),
      tooltipText: t('user.dashboard.no-of-units-per-invitation'),
    },
    { title: '' },
  ];

  const goToCreateInvitation = (
    productName,
    planId,
    productId,
    availableUnits,
    pricePerInvitation,
  ) => {
    dispatch(
      setInvitationsContext({
        productName,
        planId,
        productId,
        availableUnits,
        pricePerInvitation,
      }),
    );
    navigate(`/createinvitations`);
  };

  const customHeaderStyles = {
    header_container: styles.additional_style_header_container,
  };

  const goToCreatedInvitations = (
    productName,
    planId,
    productId,
    pricePerInvitation,
    pricePerDC,
  ) => {
    dispatch(
      setInvitationsContext({
        productName,
        planId,
        productId,
        availableUnits,
        pricePerInvitation,
        pricePerDC,
      }),
    );
    navigate(`/createdinvitations`);
  };

  useEffect(() => {
    getDashboardItems();
  }, []);

  const getUnitsLowMessage = () => {
    let displayMessage = [];
    unitsLowMessage.forEach((message) => {
      displayMessage.push(t(message.key, message.parameters));
    });
    return displayMessage.join(' ');
  };

  const getDashboardItems = async () => {
    const httpResult = await httpMessenger.apiCall('GET', 'planConsumption');
    setIsLoading(false);
    let dashboardListData;
    if (httpResult.success) {
      dashboardListData = httpResult.result;
      if (dashboardListData) {
        setIsUnitsLow(dashboardListData.unitsLowWarning);
        if (dashboardListData.unitsLowWarning) setShowWarningBanner(true);
        setAvailableUnits(dashboardListData.unitsAvailable);
        dashboardListData.unitsAvailable === 0
          ? setUnitsLowMessage([
              {
                key: 'user-dashboard.no-units-left',
                parameters: null,
              },
              {
                key: 'user-dashboard.contact-for-units',
                parameters: null,
              },
            ])
          : setUnitsLowMessage([
              {
                key: 'user-dashboard.less-units',
                parameters: null,
              },
              {
                key: 'user-dashboard.max-units-usable',
                parameters: {
                  num: dashboardListData.unitsAvailable,
                },
              },
            ]);
        setdashboardList([...dashboardListData.productGroups]);
      }
    } else {
      let errorMessage = [];
      httpResult.error.forEach((error) => {
        errorMessage.push(t(error.key, error.parameters));
      });
      setEmptyMessage(errorMessage.join(' '));
    }
  };

  const displayDashboardList = () => {
    let tableBody = transformDashboardData();
    return (
      <Table
        theadData={ColumnHeaders}
        tbodyData={tableBody}
        customClass={styles.custom_table_class}
      />
    );
  };

  const goToTeamAssessmentDetails = (productName) => {
    localStorageService.setLocalStorageValue('product', productName);
    navigate('/teamAssessment');
  };

  const getEmailContent = (productName) => {
    let newLine = '%0A';
    let link = 'mailto:hello@zortify.com';
    let subject =
      'subject=' +
      'AD ' +
      userProfileService.getCompany() +
      ' - ' +
      t('user.contact.request-item', { item: productName ? t(productName) : t('general.units') });
    let body =
      'body=' +
      t('user.contact.dear-zortify-team') +
      newLine +
      (productName
        ? t('user.contact.we-need-more-info', { product: t(productName) })
        : t('user.contact.we-need-units') + ' (' + t('user.contact.add-number-of-units') + ')') +
      newLine;
    body = body + t('user.contact.contact-us-asap') + newLine + newLine;
    body = body + t('general.thank-you');

    return {
      text: productName ? t('user.dashboard.request-information') : t('user.contact.title'),
      link: link + '?' + subject + '&' + body,
      style: productName
        ? styles.dashboard_create_invitation_button_style
        : availableUnits === 0
        ? styles.error_button_style
        : styles.warning_button_style,
    };
  };

  const hideBanner = () => {
    setShowWarningBanner(false);
  };

  const getActions = (
    productName,
    planId,
    productGroupLabel,
    productId,
    pricePerInvitation,
    pricePerDC,
  ) => {
    let actions;
    if (
      productGroupLabel === 'product-group.name.team-assessments' ||
      productGroupLabel === 'product-group.name.human-experience-services'
    ) {
      actions = [
        {
          elemType: 'link',
          props: { ...getEmailContent(productName) },
        },
        {
          elemType: 'button',
          props: {
            disabled: productGroupLabel !== 'product-group.name.team-assessments' ? true : false,
          },
          metadata: {
            text: t('user.dashboard.product-details'),
            click: () => goToTeamAssessmentDetails(productName),
            style: styles.dashboard_create_invitation_button_style,
          },
        },
      ];
    } else {
      actions = [
        {
          elemType: 'button',
          metadata: {
            text: t('user.dashboard.create-invitations'),
            click: () =>
              goToCreateInvitation(
                productName,
                planId,
                productId,
                availableUnits,
                pricePerInvitation,
              ),
            style: styles.dashboard_create_invitation_button_style,
          },
        },
        {
          elemType: 'button',
          metadata: {
            text: t('user.dashboard.product-details'),
            click: () =>
              goToCreatedInvitations(
                productName,
                planId,
                productId,
                pricePerInvitation,
                pricePerDC,
              ),
            style: styles.dashboard_create_invitation_button_style,
          },
        },
      ];
    }
    return actions;
  };

  const transformDashboardData = () => {
    let debriefingAndCoachingGroup = dashboardList.find(
      (group) => group.labelKey === 'product-group.name.human-experience-services',
    );
    let pricePerDC = -1;
    if (debriefingAndCoachingGroup !== undefined) {
      if (debriefingAndCoachingGroup.products) {
        let DCProduct = debriefingAndCoachingGroup.products.find(
          (product) => product.name === 'product-group.name.debriefing-and-coaching',
        );
        pricePerDC = DCProduct.price;
      }
    }

    let transformedDataList = dashboardList.reduce((list, productGroup, productGroupIndex) => {
      let baseRow = [
        {
          content: t(productGroup.labelKey),
          additionalStyle: styles.highlight_product_group,
        },
        { content: '' },
        { content: '' },
        { content: '' },
      ];
      let borderTopStyle = styles.border_top;
      let borderBottomStyle = styles.border_bottom;
      let children = productGroup.products.reduce((children, product, index) => {
        let childData = [
          {
            content: '',
            additionalStyle:
              index === 0
                ? borderTopStyle
                : productGroupIndex !== dashboardList.length - 1 &&
                  index === productGroup.products.length - 1
                ? borderBottomStyle
                : null,
          },
          {
            content: t(product.name),
            additionalStyle:
              index === 0
                ? borderTopStyle
                : productGroupIndex !== dashboardList.length - 1 &&
                  index === productGroup.products.length - 1
                ? borderBottomStyle
                : null,
          },
          {
            content: product.invitationsGenerated !== 0 ? product.invitationsGenerated : '',
            additionalStyle:
              index === 0
                ? borderTopStyle
                : productGroupIndex !== dashboardList.length - 1 &&
                  index === productGroup.products.length - 1
                ? borderBottomStyle
                : null,
          },
          {
            content: product.price !== 0 ? product.price : '',
            additionalStyle:
              index === 0
                ? borderTopStyle
                : productGroupIndex !== dashboardList.length - 1 &&
                  index === productGroup.products.length - 1
                ? borderBottomStyle
                : null,
          },
        ];
        let actions = getActions(
          product.name,
          productGroup.id,
          productGroup.labelKey,
          product.id,
          product.price,
          pricePerDC,
        );
        let child = Object.assign(
          {},
          {
            id: product.id,
            data: childData,
            actions: {
              content: [...actions],
              additionalStyle:
                index === 0
                  ? borderTopStyle
                  : productGroupIndex !== dashboardList.length - 1 &&
                    index === productGroup.products.length - 1
                  ? borderBottomStyle
                  : null,
            },
          },
        );
        children.push(child);
        return children;
      }, []);
      let rowData = Object.assign(
        {},
        {
          id: productGroup.id,
          data: [...baseRow],
          children: [...children],
        },
      );
      list.push(rowData);
      return list;
    }, []);
    return transformedDataList;
  };

  return (
    <PageLayout>
      <FlexView column className={styles.dashboard_container}>
        <Header
          title={userProfileService.getCompany() + ' Dashboard'}
          customStyles={customHeaderStyles}
          moreDetails={{ [t('user.dashboard.available-units')]: availableUnits }}
        />
        <FlexView className={styles.dashboard_table} column>
          {isUnitsLow && showWarningBanner && (
            <Banner
              error={availableUnits === 0}
              message={getUnitsLowMessage()}
              hyperlink={getEmailContent()}
              callbackAfterClose={hideBanner}
            />
          )}
          {dashboardList.length > 0 && displayDashboardList()}
          {isLoading && <Spinner />}
        </FlexView>
        {!isLoading && dashboardList.length <= 0 && (
          <EmptyPage message={emptyMessage || t('error.no-product-to-display')} />
        )}
        <Footer />
      </FlexView>
    </PageLayout>
  );
};
export default Dashboard;
