import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from '../components/UI/Button';
import Dropdown from '../components/UI/Dropdown';
import zortifyLogo from '../images/zortify_logo_internal.svg';
import styles from './PrivacyHeader.module.css';

const PrivacyHeader = ({ title, href, externalPreferredLanguage }) => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();

  const getLanguageDropdownMetadata = () => {
    let languageOptions = [
      {
        code: 'en',
        label: t('general.languages.english'),
      },
      {
        code: 'de',
        label: t('general.languages.german'),
      },
      {
        code: 'fr',
        label: t('general.languages.french'),
      },
    ];
    return {
      additionStyles: {
        container: styles.dropdown_additional_container_style,
        label: styles.dropdown_additional_label_style,
      },
      defaultValue:
        externalPreferredLanguage ||
        languageOptions.find((option) => option.code === i18n.language),
      options: languageOptions,
    };
  };

  const changeLanguage = (code) => {
    i18n.changeLanguage(code);
  };

  let metadata = {
    text: title,
    click: () => navigate(href),
    style: styles.custom_header_button_style,
  };

  return (
    <FlexView className={`${styles.header_container}`} column>
      <FlexView className={styles.header_content}>
        <img src={zortifyLogo} className={styles.header_logo} alt="zortifyLogo" />
        <FlexView className={styles.actions_container}>
          <Button metadata={metadata} />
          <Dropdown
            {...getLanguageDropdownMetadata()}
            key={externalPreferredLanguage || i18n.language}
            callback={(code) => changeLanguage(code)}
          />
        </FlexView>
      </FlexView>
      <hr className={`${styles.header_separator}`} />
    </FlexView>
  );
};

export default PrivacyHeader;
