import i18n, { use } from 'i18next';
import { initReactI18next } from 'react-i18next';

use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  nsSeparator: false,
  resources: {
    en: {
      translations: require('./locales/en/translations.json'),
    },
    de: {
      translations: require('./locales/de/translations.json'),
    },
    fr: {
      translations: require('./locales/fr/translations.json'),
    },
  },
  ns: ['translations'],
  defaultNS: 'translations',
});

i18n.languages = ['en', 'de', 'fr'];

export default i18n;
