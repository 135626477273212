import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';

import styles from './PrivacyPolicy.module.css';

const UseOfZortifyAnalysis = () => {
  let { t } = useTranslation();
  return (
    <FlexView className={styles.general_container} column>
      <span>{t('privacy.use-of-zortify-analysis.text1')}</span>
      <ul>
        <li>{t('privacy.use-of-zortify-analysis.text2')}</li>
        <li>{t('privacy.use-of-zortify-analysis.text3')}</li>
        <li>{t('privacy.use-of-zortify-analysis.text4')}</li>
      </ul>
      <br />
      <span>{t('privacy.use-of-zortify-analysis.text5')}</span>
      <span>{t('privacy.use-of-zortify-analysis.text6')}</span>
      <span>{t('privacy.use-of-zortify-analysis.text7')}</span>
      <span>{t('privacy.use-of-zortify-analysis.text8')}</span>
      <span>{t('privacy.use-of-zortify-analysis.text9')}</span>
    </FlexView>
  );
};

export default UseOfZortifyAnalysis;
