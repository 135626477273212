import React, { useEffect, useState } from 'react';
import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';

import Banner from '../components/UI/Banner';
import Button from '../components/UI/Button';
import Modal from '../components/UI/Modal';
import TextArea from '../components/UI/TextArea';
import { UNDEFINED } from '../consts/CommonConstants';
import searchPlusIcon from '../images/search_plus_icon.svg';
import httpMessenger from '../services/HTTPMessenger';
import Spinner from '../Shared/Spinner';
import validationService from '../Shared/ValidationService';
import styles from './Comment.module.css';
import CreatedInvitationsPresenter from './CreatedInvitationsPresenter';

const Comment = (props) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState(props.comment || '');
  const [tempComment, setTempComment] = useState(props.comment);
  const [isEditing, setIsEditing] = useState(props.comment ? false : true);
  const [warningMessage, setWarningMessage] = useState('');
  const [bannerActionMetadata, setBannerActionMetadata] = useState(null);
  const [commentMetadata, setCommentMetadata] = useState({
    placeholder: t('product.details-enter-comment'),
    defaultValue: comment,
    rows: 6,
    maxlength: 200,
    readOnly: props.comment ? true : false,
    required: true,
    additionalStyles: {
      textarea: styles.textarea_additional_style,
      container: styles.textarea_container_additional_style,
    },
    submitValidations: [
      {
        type: 'required',
        attributes: {},
      },
    ],
  });

  const commentRef = React.createRef();

  const [modalInfo, setModalInfo] = useState({
    show: false,
    showErrorBanner: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  let createdInvitationsPresenter = new CreatedInvitationsPresenter();

  useEffect(() => {
    if (modalInfo.show) showAddCommentModal();
  }, [tempComment]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAddCommentButtonProps = () => {
    let metadata = {
      text: '+',
      click: (e) => showAddCommentModal(e),
      style: styles.add_comment_button_style,
    };
    return metadata;
  };

  const bannerMetadata = {
    style: {
      message_span: styles.banner_message_span,
      banner_container: styles.banner_container,
    },
  };

  const showAddCommentModal = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (comment === '') setIsEditing(true);
    setModalInfo({
      show: true,
      showErrorBanner: false,
      formActions: {
        rightButton: {
          text: t('general.save'),
          action: handleSubmit,
          style: styles.modal_submit_button_style,
        },
        leftButton: {
          text: t('general.cancel'),
          action: cancelSave,
          style: styles.modal_cancel_button_style,
        },
      },
      close: cancelSave,
    });
  };

  const showBanner = () => {
    setModalInfo((modalInfo) => ({ ...modalInfo, ...{ showErrorBanner: true } }));
  };

  const hideBanner = () => {
    setModalInfo((modalInfo) => ({ ...modalInfo, ...{ showErrorBanner: false } }));
  };

  const cancelSaveActionMetadata = () => {
    let metadata = {
      text: t('general.proceed'),
      click: modalClose,
      style: styles.banner_error_button_style,
    };
    return metadata;
  };

  const cancelSave = () => {
    if (isEditing && tempComment !== comment) {
      setWarningMessage(
        t('product.details-you-didnt-save-comment') +
          ' ' +
          t('general.unsaved-changes-will-be-lost') +
          ' ' +
          t('general.close-anyway'),
      );
      setBannerActionMetadata(cancelSaveActionMetadata());
      showBanner();
    } else modalClose();
  };

  const handleChange = (value) => {
    setTempComment(value); // Update the temporary comment state
    if (error) {
      setError(false);
      setErrorMessage('');
    }
  };

  const deleteCommentActionMetadata = () => {
    let metadata = {
      text: t('general.proceed'),
      click: deleteComment,
      style: styles.banner_error_button_style,
    };
    return metadata;
  };

  const showConfirmDeleteNotification = () => {
    setWarningMessage(
      t('product-details-delete-comment-is-irreversible') +
        ' ' +
        t('product-details-are-you-sure-to-proceed'),
    );
    setBannerActionMetadata(deleteCommentActionMetadata());
    showBanner();
  };

  const deleteComment = () => {
    setTempComment('');
    setComment('');
    setCommentMetadata(Object.assign({}, commentMetadata, { defaultValue: '' }));
    addUpdateComment('');
  };

  const editComment = () => {
    setIsEditing(true);
    setCommentMetadata(Object.assign({}, commentMetadata, { readOnly: false }));
    commentRef.current.focus();
    //To make cursor point at the end of the text
    commentRef.current.setSelectionRange(-1, -1);
  };

  const headerButtons = [
    {
      alt_text: 'editComment',
      click: editComment,
      src: 'edit_blue_icon.svg',
    },
    {
      alt_text: 'deleteComment',
      click: showConfirmDeleteNotification,
      src: 'delete_icon.svg',
    },
  ];

  const modalClose = () => {
    setIsEditing(false);
    setError(false);
    setErrorMessage('');
    if (tempComment && comment) {
      setCommentMetadata(Object.assign({}, commentMetadata, { readOnly: true }));
    }
    setModalInfo({
      show: false,
      showErrorBanner: false,
      formActions: {
        rightButton: {
          text: null,
          action: null,
          style: null,
        },
        leftButton: {
          text: null,
          action: null,
          style: null,
        },
      },
      close: null,
    });
  };

  const handleSubmit = () => {
    if (tempComment === comment) {
      setError(true);
      setErrorMessage('product-details-edit-comment-before-saving');
    } else {
      if (handleValidations(tempComment)) {
        addUpdateComment();
        setComment(tempComment);
        setCommentMetadata(
          Object.assign({}, commentMetadata, { defaultValue: tempComment, readOnly: true }),
        );
      }
    }
  };

  const handleValidations = (validateValue) => {
    let isValid, message;
    let submitValidations = [
      {
        type: 'required',
        attributes: {},
      },
    ];

    for (let validation of submitValidations) {
      [isValid, message] = [
        ...validationService.validate(validation.type, validateValue, validation.attributes),
      ];
      if (!isValid) {
        setError(true);
        setErrorMessage(message);
        break;
      } else {
        setError(false);
        setErrorMessage(message);
      }
    }
    return isValid ? true : false;
  };

  const addUpdateComment = async (updatedComment) => {
    let newComment = updatedComment !== UNDEFINED ? updatedComment : tempComment;
    const body = {
      value: newComment,
      field: 'comment',
    };
    setIsLoading(true);
    modalClose();
    let httpResult = await httpMessenger.apiCall('PATCH', 'invitationUpdate', null, body, null, [
      props.id,
    ]);
    if (httpResult.success) {
      let commentElement = createdInvitationsPresenter.getCommentElementWithProps(
        props.id,
        newComment,
        props.actionsCallback,
      );
      props.actionsCallback &&
        props.actionsCallback(httpResult.result.id, 'comment', commentElement);
    } else
      props.actionsCallback &&
        props.actionsCallback(null, null, null, null, false, httpResult.error);
    setIsLoading(false);
  };

  return (
    <>
      {!comment && <Button metadata={getAddCommentButtonProps()} />}
      {comment && (
        <FlexView>
          <span className={styles.comment_span}>{comment}</span>
          <img
            className={styles.search_plus_icon}
            src={searchPlusIcon}
            onClick={(e) => showAddCommentModal(e)}
          />
        </FlexView>
      )}
      {modalInfo.show && (
        <Modal
          onClose={modalInfo.close}
          formActions={modalInfo.formActions}
          headerButtons={headerButtons}
        >
          <FlexView column className={styles.modal_content_container}>
            {modalInfo.showErrorBanner && (
              <Banner
                error={true}
                message={warningMessage}
                metadata={bannerMetadata}
                callbackAfterClose={hideBanner}
                action={bannerActionMetadata}
              />
            )}
            <TextArea
              inputRef={commentRef}
              {...commentMetadata}
              error={error}
              errorMessage={errorMessage}
              callback={(value) => handleChange(value)}
            />
          </FlexView>
        </Modal>
      )}
      {isLoading && <Spinner />}
    </>
  );
};

export default Comment;
