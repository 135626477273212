import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';

import styles from './PrivacyPolicy.module.css';

const ComfortFunctions = () => {
  let { t } = useTranslation();
  return (
    <FlexView className={styles.general_container} column>
      <span>{t('privacy.comfort-functions.text1')}</span>
    </FlexView>
  );
};

export default ComfortFunctions;
