export default class DateTimeService {
  locale;

  constructor() {
    this.locale = 'en-GB';
  }

  toLocaleDateString = (dateString, locale) => {
    return new Date(dateString).toLocaleDateString(locale || this.locale);
  };

  toJSONLocal(date) {
    //This function gets local time by removing timezone offset from ISO string.
    var local = new Date(date);
    local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return local.toJSON().slice(0, 10);
  }
}
