import { useState } from 'react';
import FlexView from 'react-flexview/lib';

import closeIcon from '../../images/close_icon.svg';
import errorIcon from '../../images/error_icon.svg';
import warningIcon from '../../images/important_note.svg';
import styles from './Banner.module.css';
import Button from './Button';

const Banner = (props) => {
  const [showBanner, setShowBanner] = useState(true);
  let imgType = props.error ? errorIcon : warningIcon;
  let customContainerStyle = props.error
    ? ` ${styles.container_error}`
    : ` ${styles.container_warning}`;
  const metadata = props.metadata || {};
  let additionalStyles = metadata.style || {};

  const hideBanner = () => {
    setShowBanner(false);
    if (props.callbackAfterClose) props.callbackAfterClose();
  };

  return (
    <>
      {showBanner && (
        <FlexView
          className={`${customContainerStyle} ${styles.banner_container} ${additionalStyles.banner_container}`}
        >
          <img src={imgType} className={styles.banner_icon} alt="banner_icon" />
          <span className={`${styles.banner_message} ${additionalStyles.message_span}`}>
            {props.message}
          </span>
          {props.action && <Button metadata={props.action} />}
          {props.hyperlink && (
            <a href={props.hyperlink.link} className={props.hyperlink.style}>
              {props.hyperlink.text}
            </a>
          )}
          {props.callbackAfterClose && (
            <img
              src={closeIcon}
              onClick={hideBanner}
              className={styles.banner_close}
              alt="baner_close"
            />
          )}
        </FlexView>
      )}
    </>
  );
};

export default Banner;
