import React from 'react';
import FlexView from 'react-flexview/lib';

import styles from '../styles/ToBeImplemented.module.css';
import PageLayout from './PageLayout';

class ToBeImplemented extends React.PureComponent {
  render() {
    return (
      <PageLayout>
        <FlexView className={styles.tobeimplemented_container}>To be implemented</FlexView>
      </PageLayout>
    );
  }
}
export default ToBeImplemented;
