import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';

import closeIcon from '../../images/close_dark.svg';
import styles from './Chip.module.css';

const Chip = ({ metadata, showCloseIcon, click, shouldTranslate }) => {
  const { t } = useTranslation();
  return (
    <FlexView className={`${styles.chip_container} ${metadata.style?.container}`}>
      <span className={`${styles.chip_span} ${metadata.style?.span}`}>
        {shouldTranslate ? t(metadata.text) : metadata.text}
      </span>
      {showCloseIcon && <img src={closeIcon} className={styles.close_icon} onClick={click} />}
    </FlexView>
  );
};

export default Chip;
