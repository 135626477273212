import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';

import styles from './LegalDisclosure.module.css';

const WebsiteProvider = () => {
  let { t } = useTranslation();
  return (
    <FlexView className={styles.general_container} column>
      <span>Zortify S.A.</span>
      <span>{t('privacy.general-info.text5')}</span>
      <span>9, Rue du Laboratoire, 1911 Luxembourg</span>
      <span>Luxembourg</span>
      <span>
        e-mail:{' '}
        <a href="mailto:privacy@zortify.com" className={styles.general_info_href}>
          hello@zortify.com
        </a>
      </span>
    </FlexView>
  );
};

export default WebsiteProvider;
