import React from 'react';

import { ElementMap } from '../consts/ElementTypes';

export default class DomRender extends React.PureComponent {
  getElementToRender = (elemType) => {
    if (ElementMap[elemType]) {
      return React.createElement(ElementMap[elemType], {
        metadata: this.props.metadata,
        callback: this.props.callback,
        ...this.props,
      });
    } else {
      return null;
    }
  };
  render() {
    return <>{this.getElementToRender(this.props.elemType)}</>;
  }
}
