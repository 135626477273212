class LocalStorageService {
  getLocalStorageValue = (key) => {
    try {
      if (window && window.localStorage) {
        return window.localStorage.getItem(key);
      }
    } catch (Err) {
      console.log('Error retrieving from localstorage', Err);
    }
  };

  setLocalStorageValue = (key, value) => {
    try {
      if (window && window.localStorage) {
        window.localStorage.setItem(key, value);
      }
    } catch (Err) {
      console.log('Error saving to localstorage', Err);
    }
  };

  deleteLocalStorageValue = (key) => {
    try {
      if (window && window.localStorage) {
        return window.localStorage.removeItem(key);
      }
    } catch (Err) {
      console.log('Error removing from localstorage', Err);
    }
  };

  deleteAllLocalStorageValues = () => {
    try {
      if (window && window.localStorage) {
        return window.localStorage.clear();
      }
    } catch (Err) {
      console.log('Error removing some(or all) items from localstorage', Err);
    }
  };
}

const localStorageService = new LocalStorageService();
export default localStorageService;
