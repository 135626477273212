import { useTranslation } from 'react-i18next';

import ConditionalWrapper from '../HOCs/ConditionalWrapper';
import styles from './IconButton.module.css';
import CustomTooltip from './Tooltip';

const IconButton = (props) => {
  const { t } = useTranslation();
  let metadata = props.metadata;
  let additionalStyles = metadata.style;

  return (
    <ConditionalWrapper
      condition={metadata.tooltipText}
      wrapper={(children) => (
        <CustomTooltip title={t(metadata.tooltipText)} placement={metadata.tooltipPlacement}>
          {children}
        </CustomTooltip>
      )}
    >
      <img
        className={`${styles.icon} ${additionalStyles}`}
        src={require(`../../images/${metadata.src}`)}
        alt={metadata.alt}
        onClick={metadata.click}
      />
    </ConditionalWrapper>
  );
};

export default IconButton;
