import { useEffect, useState } from 'react';
import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import PageLayout from '../../components/PageLayout';
import PrivacyHeader from '../PrivacyHeader';
import BoardOfDirectors from './BoardOfDirectors';
import CompanyRegisterationNumber from './CompanyRegisterationNumber';
import styles from './LegalDisclosure.module.css';
import Vat from './VAT';
import WebsiteProvider from './WebsiteProvider';

const legalConfig = [
  {
    displayTitle: 'legal-disclosure.website-provider',
    content: <WebsiteProvider />,
  },
  {
    displayTitle: 'legal-disclosure.board-of-directors',
    content: <BoardOfDirectors />,
  },
  {
    displayTitle: 'legal-disclosure.VAT',
    content: <Vat />,
  },
  {
    displayTitle: 'legal-disclosure.company-registration-number',
    content: <CompanyRegisterationNumber />,
  },
];

const LegalDisclosure = () => {
  const [legalContent, setLegalContent] = useState({ data: [] });
  let { t } = useTranslation();

  const { lang } = useParams();

  const getContent = () => {
    let privacyContentsVm = { data: [] };
    legalConfig.map((item) => {
      privacyContentsVm.data.push({
        displayTitle: item.displayTitle || item.title,
        content: item.content,
      });
      return true;
    });
    return privacyContentsVm;
  };

  useEffect(() => {
    let legalContentVm;
    legalContentVm = getContent();
    setLegalContent(legalContentVm);
  }, []);

  return (
    <PageLayout hideNav>
      <FlexView className={styles.base_container} column>
        <PrivacyHeader
          title={t('privacy.title')}
          href="/privacypolicy"
          externalPreferredLanguage={lang}
        />
        <FlexView className={styles.base_container}>
          <FlexView className={styles.menu_container} column></FlexView>
          <FlexView className={styles.content_container} column>
            <span className={styles.legal_disclosure_title}>{t('legal-disclosure.title')}</span>
            {legalContent.data.map((item, index) => (
              <FlexView key={index} className={styles.nav_individual_content_container} column>
                <span className={styles.display_title}>{t(item.displayTitle)}</span>
                {item.content}
              </FlexView>
            ))}
          </FlexView>
        </FlexView>
      </FlexView>
    </PageLayout>
  );
};

export default LegalDisclosure;
