import FlexView from 'react-flexview/lib';

import closeIcon from '../../images/close_icon.svg';
import helpIcon from '../../images/i-icon.svg';
import Button from './Button';
import styles from './Modal.module.css';
import CustomTooltip from './Tooltip';

const Modal = (props) => {
  const rightButtonMetadata = {
    click: props.formActions.rightButton ? props.formActions.rightButton.action : null,
    text: props.formActions.rightButton ? props.formActions.rightButton.text : null,
    disabled: props.formActions.rightButton ? props.formActions.rightButton.disabled : null,
    leftIcon: props.formActions.rightButton ? props.formActions.rightButton.leftIcon : null,
    style: `${styles.modal_button_custom_style} ${
      props.formActions.rightButton ? props.formActions.rightButton.style : null
    }`,
  };

  const leftButtonMetadata = {
    click: props.formActions.leftButton ? props.formActions.leftButton.action : null,
    text: props.formActions.leftButton ? props.formActions.leftButton.text : null,
    disabled: props.formActions.leftButton ? props.formActions.leftButton.disabled : null,
    style: `${styles.modal_button_custom_style} ${
      props.formActions.leftButton ? props.formActions.leftButton.style : null
    }`,
  };

  const doNothing = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const backDropClickHandler = (event) => {
    doNothing(event);
    if (props.onClose) props.onClose();
  };

  return (
    <>
      <div
        className={styles.modal__backdrop}
        onClick={(event) => backDropClickHandler(event)}
      ></div>
      <div className={styles.modal__container} onClick={(event) => doNothing(event)}>
        <FlexView column>
          {(props.headerButtons || props.headerText) && (
            <div className={styles.modal_header_container}>
              <FlexView>
                {props.headerText && (
                  <span className={styles.modal_header_text}>{props.headerText}</span>
                )}
                {props.headerHint && (
                  <CustomTooltip title={props.headerHint} placement="right">
                    <img src={helpIcon} className={styles.modal_header_tooltip} />
                  </CustomTooltip>
                )}
              </FlexView>
              {props.headerButtons && (
                <FlexView className={styles.modal_header_buttons_container}>
                  {props.headerButtons.map((action, index) => (
                    <img
                      key={index}
                      className={styles.modal_header_icons}
                      onClick={action.click}
                      src={require(`../../images/${action.src}`)}
                      alt={action.alt_text}
                    />
                  ))}
                </FlexView>
              )}
              <FlexView>
                <img
                  className={`${styles.modal_header_icons} ${styles.modal_header_close_icon}`}
                  onClick={props.onClose}
                  src={closeIcon}
                  alt="close_icon"
                />
              </FlexView>
            </div>
          )}
          <FlexView>{props.children}</FlexView>
          <div
            className={`${styles.modal_button_container} ${
              props.formActions.leftButton ? styles.modal_two_buttons_container : null
            }`}
          >
            {props.formActions.leftButton && <Button metadata={leftButtonMetadata} />}
            <Button metadata={rightButtonMetadata} disabled={rightButtonMetadata.disabled} />
          </div>
        </FlexView>
      </div>
    </>
  );
};
export default Modal;

/* If only one button is needed in actions bar at the bottom, send it in the format
formActions: {
  rightButton: {
    text: "Save",
    action: handleSubmit,
    style: styles.modal_submit_button_style
  }
}

If 2 buttons needed, send in the format
formActions: {
  rightButton: {
    text: "Save",
    action: handleSubmit,
    style: styles.modal_submit_button_style
  },
  leftButton: {
    text: "Cancel",
    action: cancel,
    style: styles.modal_cancel_button_style
  }
}
*/
