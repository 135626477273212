import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';

import styles from './PrivacyPolicy.module.css';

const ContactAndRightOfAppeal = () => {
  let { t } = useTranslation();
  return (
    <FlexView className={styles.general_container} column>
      <span>{t('privacy.contact-and-right-of-appeal.text1')}</span>
      <br />
      <span>{t('privacy.contact-and-right-of-appeal.text2')}</span>
      <span>15, Boulevard du Jazz </span>
      <span>L-4370 Belvaux</span>
      <a
        href="https://cnpd.public.lu/en.html"
        target="_blank"
        className={styles.general_info_href}
        rel="noreferrer"
      >
        https://cnpd.public.lu/en.html
      </a>
    </FlexView>
  );
};

export default ContactAndRightOfAppeal;
