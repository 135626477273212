import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useContext } from 'react';

import ThemeContext from '../../contexts/ThemeContext';
import styles from './Button.module.css';

const Button = (props) => {
  const { theme } = useContext(ThemeContext);
  let metadata = props.metadata || {};

  const className = clsx(
    styles.button,
    {
      [styles.cancel]: props.cancel,
      [styles.disabled]: props.disabled,
    },
    styles['button_' + theme],
    metadata.style,
  );

  return (
    <button className={className} disabled={props.disabled} onClick={metadata.click}>
      {metadata.leftIcon && (
        <img
          src={require(`../../images/${metadata.leftIcon}`)}
          className={styles.left_icon}
          alt="left_icon"
        />
      )}
      <span className={styles.vertical_align_middle}>{metadata.text}</span>
      {metadata.rightIcon && (
        <img
          src={require(`../../images/${metadata.rightIcon}`)}
          className={styles.right_icon}
          alt="right_icon"
        />
      )}
    </button>
  );
};

Button.propTypes = {
  cancel: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Button;
