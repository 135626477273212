import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';

import styles from './PrivacyPolicy.module.css';

const DirectCorrespondence = () => {
  let { t } = useTranslation();
  return (
    <FlexView className={styles.general_container} column>
      <span>{t('privacy.direct-correspondence.text1')}</span>
      <br />
      <span>{t('privacy.direct-correspondence.text2')}</span>
      <br />
      <span>{t('privacy.direct-correspondence.text3')}</span>
      <span>{t('privacy.direct-correspondence.text4')}</span>
      <span>{t('privacy.direct-correspondence.text5')}</span>
      <span>{t('privacy.direct-correspondence.text6')}</span>
      <span>{t('privacy.direct-correspondence.text7')}</span>
      <span>{t('privacy.direct-correspondence.text8')}</span>
      <span>{t('privacy.direct-correspondence.text9')}</span>
    </FlexView>
  );
};

export default DirectCorrespondence;
