import { useEffect, useState } from 'react';
import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';

import { NULL, UNDEFINED } from '../../consts/CommonConstants';
import RadioButton from './RadioButton';
import styles from './RadioGroup.module.css';

const RadioGroup = ({
  required,
  defaultValue,
  error,
  errorMessage,
  errorMessageParams = {},
  options,
  callback,
  code,
}) => {
  // let metadata = props.metadata || {};

  const [selectedValue, setSelectedValue] = useState(null);
  const { t } = useTranslation();
  const handleChange = (value) => {
    setSelectedValue(value);
    if (callback !== UNDEFINED) callback(value);
  };

  useEffect(() => {
    if (defaultValue !== UNDEFINED && defaultValue !== NULL) {
      handleChange(defaultValue.code);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FlexView column onChange={(e) => handleChange(e.target.value)}>
      {/* {required && <span className={styles.required}>*</span>} */}
      <FlexView column>
        {options &&
          options.map((option, index) => {
            return (
              <RadioButton
                key={index}
                checked={selectedValue === option.code}
                name={code}
                {...option}
              />
            );
          })}
      </FlexView>
      <FlexView className={styles.radiogroup_error_message_container} column>
        {error && (
          <span className={styles.radiogroup_error_message_span}>
            {t(errorMessage, errorMessageParams)}
          </span>
        )}
      </FlexView>
    </FlexView>
  );
};

export default RadioGroup;
