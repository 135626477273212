import clsx from 'clsx';
import { useContext } from 'react';

import ThemeContext from '../../contexts/ThemeContext';
import styles from './Link.module.css';

const Link = ({ text, link, style }) => {
  const { theme } = useContext(ThemeContext);

  const className = clsx(styles.link, styles['link_' + theme], style);

  return (
    <a href={link} className={className}>
      {text}
    </a>
  );
};

export default Link;
