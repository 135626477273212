import React from 'react';

/* This function can be used as an extension to the
react useState Hook. the setState() here, essentially
does not modify the actual value of the state variable.
It just returns the latest updated value everytime. */

export function useExtendedState(initialState) {
  const [state, setState] = React.useState(initialState);
  const getLatestState = () => {
    return new Promise((resolve) => {
      setState((currentState) => {
        resolve(currentState);
        return currentState;
      });
    });
  };

  return [state, setState, getLatestState];
}

/* Possible Use Case: When a state variable has to be modified
inside a callback function that has been sent as a reference
"prop" to a child component, we always get the initial values
of the state variable inside the callback function because
new instance of callback is created everytime we pass it as a
prop to child component with initial state variable values
(inspite of the variable updated in various other operations along the way)
To solve this problem, we call a setState()
here to always access the latest value and return it back */
