import { configureStore } from '@reduxjs/toolkit';

import { companyListReducer } from './reducers/CompanyListReducer';
import { invitationsReducer } from './reducers/InvitationsReducer';
import { userListReducer } from './reducers/UserListReducer';

export const store = configureStore({
  reducer: {
    invitations: invitationsReducer,
    companyList: companyListReducer,
    userList: userListReducer,
  },
});
