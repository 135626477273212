import { COMPARISON_SIGNS } from '../../consts/CommonConstants';
import DateTimeService from '../../services/DateTimeService';
import styles from './NewPlan.module.css';

export default class NewPlanPresenter {
  dateTime = new DateTimeService();

  getFormFields = () => {
    const formFields = [
      {
        label: 'Description',
        code: 'description',
        type: 'text',
        hint: 'Enter a short description or invoice number',
        placeholder: 'Enter the description',
        style: {
          container: styles.text_additional_style,
        },
        instantValidations: [
          {
            type: 'shortLength',
            attributes: {
              minLength: 5,
              errorMessage: 'Your description is too short. Please enter atleast [5] characters.',
            },
          },
        ],
        submitValidations: [
          {
            type: 'required',
            attributes: {},
          },
        ],
      },
      {
        label: 'Units amount',
        code: 'totalUnits',
        type: 'text',
        inputType: 'number',
        placeholder: '0',
        style: {
          container: styles.text_additional_style,
        },
        instantValidations: [
          {
            type: 'valueCompare',
            attributes: {
              compareSign: COMPARISON_SIGNS.GT,
              expectation: 0,
              errorMessage: 'Invalid amount of units. Please try again.',
            },
          },
        ],
        submitValidations: [
          {
            type: 'required',
            attributes: {},
          },
        ],
      },
      {
        label: 'Expiration Date',
        code: 'expirationDate',
        type: 'text',
        inputType: 'date',
        min: this.dateTime.toJSONLocal(new Date()), //new Date().toISOString().split('T')[0],
        placeholder: 'dd-mm-yyyy',
        shouldDisableKeyDown: true,
        style: {
          container: styles.text_additional_style,
        },
        submitValidations: [
          {
            type: 'required',
            attributes: {},
          },
        ],
      },
    ];
    return formFields;
  };
}
