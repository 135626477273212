import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';

import styles from './PrivacyPolicy.module.css';

const Cookies = () => {
  let { t } = useTranslation();
  return (
    <FlexView className={styles.general_container} column>
      <span>{t('privacy.cookies.text1')}</span>
      <br />
      <span>{t('privacy.cookies.text2')}</span>
      <ul>
        <li>{t('privacy.cookies.text3')}</li>
        <li>{t('privacy.cookies.text4')}</li>
      </ul>
      <br />
      <span>{t('privacy.cookies.text5')}</span>
      <br />
      <span>{t('privacy.cookies.text6')}</span>
      <br />
      <span>{t('privacy.cookies.text7')}</span>
      <br />
      <span>{t('privacy.cookies.text8')}</span>
      <br />
      <span>{t('privacy.cookies.text9')}</span>
    </FlexView>
  );
};

export default Cookies;
