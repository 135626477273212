import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';

import userProfileService from '../../services/UserProfileService';
import Footer from '../Footer';
import Header from '../Header';
import PageLayout from '../PageLayout';
import styles from './Contact.module.css';

const Contact = () => {
  const { t } = useTranslation();
  const title = t('user.contact.title');

  const customFooterStyles = {
    container: styles.footer_custom_style,
  };

  const customHeaderStyles = {
    headerLine: styles.header_custom_style,
  };

  const getLink = () => {
    let newLine = '%0A';
    let link = 'mailto:hello@zortify.com';
    let subject =
      'subject=' + 'AD ' + userProfileService.getCompany() + ' - ' + t('general.support');
    let body =
      'body=' +
      t('user.contact.dear-zortify-team') +
      newLine +
      newLine +
      t('user.contact.we-need-support') +
      newLine +
      t('user.contact.check-respective-topic') +
      newLine +
      newLine;
    let requestItem = t('user.contact.request-item', { item: t('general.units') });
    body =
      body +
      '( ) ' +
      requestItem[0].toUpperCase() +
      requestItem.substring(1) +
      ' (' +
      t('user.contact.add-number-of-units') +
      ')' +
      newLine;
    body =
      body +
      '( ) ' +
      t('user.contact.didnt-receive-report') +
      ' (' +
      t('user.contact.add-invitation-link-or-token') +
      ')' +
      newLine;
    body =
      body +
      '( ) ' +
      t('user.contact.technical-issue') +
      ' (' +
      t('general.please-describe') +
      ')' +
      newLine;
    body =
      body +
      '( ) ' +
      t('general.other') +
      ' (' +
      t('general.please-describe') +
      ')' +
      newLine +
      newLine;
    body = body + t('user.contact.contact-us-asap') + newLine + newLine;
    body = body + t('general.thank-you');
    return link + '?' + subject + '&' + body;
  };

  return (
    <PageLayout>
      <FlexView column>
        <Header title={title} customStyles={customHeaderStyles} />
        <FlexView className={styles.contact_us_container} column>
          <span className={styles.contact_get_in_touch_title}>
            {t('user.contact.get-in-touch')}
          </span>
          <span className={styles.contact_description}>{t('user.contact.description')}</span>
          <a href={getLink()} className={styles.mailto_link}>
            {t('user.contact.send-button')}
          </a>
        </FlexView>
        <Footer customStyles={customFooterStyles} />
      </FlexView>
    </PageLayout>
  );
};
export default Contact;
