import helpIcon from '../../images/help_icon.svg';
import CustomTooltip from '../UI/Tooltip.js';
import styles from './TableHeadItem.module.css';

const TableHeadItem = ({ item }) => {
  return (
    <th>
      {item.title}
      {item.tooltipText && (
        <CustomTooltip title={item.tooltipText}>
          <img src={helpIcon} className={styles.help_icon} />
        </CustomTooltip>
      )}
    </th>
  );
};

export default TableHeadItem;
