import { useEffect, useState } from 'react';

import { UNDEFINED } from '../../consts/CommonConstants';
import styles from './Checkbox.module.css';

const Checkbox = (props) => {
  const [isChecked, toggleChecked] = useState(props.checked);

  const handleChange = (initialValue) => {
    let valueToSet = initialValue !== UNDEFINED ? initialValue : !isChecked;
    toggleChecked(valueToSet);
    if (props.callback !== UNDEFINED) {
      let isClicked = initialValue === UNDEFINED;
      props.callback(valueToSet, isClicked);
    }
  };

  useEffect(() => {
    handleChange(props.checked);
  }, [props.checked]); // eslint-disable-line react-hooks/exhaustive-deps

  const doNothing = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <input
      type="checkbox"
      checked={isChecked}
      onClick={(e) => doNothing(e)}
      onChange={(e) => handleChange()}
      className={styles.checkbox_input}
    />
  );
};

export default Checkbox;
