import { createReducer } from '@reduxjs/toolkit';

import { setCompanyListContext } from '../actions/CompanyActions';

const initialState = {};

export const companyListReducer = createReducer(initialState, (builder) => {
  builder.addCase(setCompanyListContext, (state, action) => {
    state.context = action.payload;
  });
});
