import React from 'react';
import FlexView from 'react-flexview';
import { useTranslation } from 'react-i18next';

import Chip from '../components/UI/Chip';
import barChartIcon from '../images/bar_chart_icon.svg';
import bellIcon from '../images/bell_icon.svg';
import messagesIcon from '../images/messages_icon.svg';
import rocketIcon from '../images/rocket_icon.svg';
import smileyIcon from '../images/smiley_icon.svg';
import styles from './TeamAssessment.module.css';

const teamFlow = [
  {
    title: 'process.kickoff.title',
    description: 'team.process.kickoff.description',
    sessionType: 'team-assessment.session-type.digital',
    durationType: 'general.duration.minutes',
    duration: '45',
    icon: bellIcon,
  },
  {
    title: 'process.online-assessment.title',
    description: 'team.process.online-assessment.description',
    sessionType: 'team-assessment.session-type.survey',
    durationType: 'general.duration.minutes',
    duration: '30',
    icon: barChartIcon,
  },
  {
    title: 'process.one-to-one-debriefing.title',
    description: 'team.process.one-to-one-debriefing.description',
    sessionType: 'team-assessment.session-type.de-briefing',
    durationType: 'general.duration.minutes',
    duration: '45',
    icon: messagesIcon,
  },
  {
    title: 'process.team-workshop.title',
    description: 'team.process.team-workshop.description',
    sessionType: 'team-assessment.session-type.onsite',
    durationType: 'general.duration.hours',
    duration: '4',
    icon: rocketIcon,
  },
  {
    title: 'process.daily-life.title',
    description: 'team.process.daily-life.description',
    icon: smileyIcon,
  },
];

const leaderFlow = [
  {
    title: 'process.kickoff.title',
    description: 'leader.process.kickoff.description',
    sessionType: 'team-assessment.session-type.digital',
    durationType: 'general.duration.minutes',
    duration: '60',
    icon: bellIcon,
  },
  {
    title: 'process.online-assessment.title',
    description: 'leader.process.online-assessment.description',
    sessionType: 'team-assessment.session-type.survey',
    durationType: 'general.duration.minutes',
    duration: '30',
    icon: barChartIcon,
  },
  {
    title: 'process.one-to-one-debriefing.title',
    description: 'leader.process.one-to-one-debriefing.description',
    sessionType: 'team-assessment.session-type.workshop-digital-or-onsite',
    durationType: 'general.duration.minutes',
    duration: '90',
    icon: messagesIcon,
  },
  {
    title: 'process.team-workshop.title',
    description: 'leader.process.team-workshop.description',
    sessionType: 'team-assessment.session-type.session-digital-or-onsite',
    durationType: 'general.duration.minutes',
    duration: '90',
    icon: rocketIcon,
  },
];

const ProductFlow = ({ productName }) => {
  const { t } = useTranslation();
  const productFlow = productName === 'Zortify Leader' ? leaderFlow : teamFlow;
  const getChipMetadata = (text) => {
    return {
      text: t(text),
      style: {
        container: styles.chip_container,
        span: styles.chip_span,
      },
    };
  };

  return (
    <>
      <FlexView className={styles.product_details_container} column>
        {productFlow.map((step) => {
          return (
            <FlexView className={styles.product_flow_outer_container}>
              <img src={step.icon} className={styles.product_flow_icon} />
              <FlexView className={styles.product_flow_container} column>
                <span className={styles.product_details_topic_title}>{t(step.title)}</span>
                {step.sessionType && (
                  <FlexView>
                    <Chip metadata={getChipMetadata(step.sessionType)} />
                    {step.durationType && step.duration && (
                      <span className={styles.product_flow_duration_span}>
                        {t(step.durationType, { dur: step.duration })}
                      </span>
                    )}
                  </FlexView>
                )}
                <span className={styles.product_details_topic_description}>
                  {t(step.description)}
                </span>
              </FlexView>
            </FlexView>
          );
        })}
      </FlexView>
    </>
  );
};

export default ProductFlow;
