import React, { useState } from 'react';
import FlexView from 'react-flexview';
import { useTranslation } from 'react-i18next';

import Footer from '../components/Footer';
import Header from '../components/Header';
import PageLayout from '../components/PageLayout';
import userProfileService from '../services/UserProfileService';
import localStorageService from '../Shared/LocalStorageService';
import ProductDetails from './ProductDetails';
import ProductFlow from './ProductFlow';
import styles from './TeamAssessment.module.css';

const teamDetailsTabs = [
  {
    title: 'user.dashboard.product-details',
  },
  {
    title: 'general.process',
  },
];

const TeamAssessment = () => {
  const { t } = useTranslation();
  const productName = localStorageService.getLocalStorageValue('product');
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const navigateToTab = (index) => {
    setActiveTabIndex(index);
  };

  const getEmailContent = () => {
    let newLine = '%0A';
    let link = 'mailto:hello@zortify.com';
    let subject =
      'subject=' +
      'AD ' +
      userProfileService.getCompany() +
      ' - ' +
      t('user.contact.request-item', { item: productName });
    let body =
      'body=' +
      t('user.contact.dear-zortify-team') +
      newLine +
      t('user.contact.we-need-more-info', { product: productName }) +
      newLine;
    body = body + t('user.contact.contact-us-asap') + newLine + newLine;
    body = body + t('general.thank-you');

    return {
      text: t('user.dashboard.request-information'),
      link: link + '?' + subject + '&' + body,
      style: styles.custom_header_button_style,
    };
  };

  const headerActionButtonConfig = {
    elemType: 'link',
    props: { ...getEmailContent(productName) },
  };

  // useEffect(() => {
  //   return () => {
  //     localStorageService.deleteLocalStorageValue('product')
  //   }
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PageLayout>
      <FlexView column>
        <Header title={productName} customElement={headerActionButtonConfig} />
        <FlexView className={styles.details_container} column>
          <FlexView>
            {teamDetailsTabs.map((tab, index) => {
              return (
                <div
                  key={index}
                  className={`${styles.inactive_tab} ${
                    activeTabIndex === index ? styles.active_tab : null
                  }`}
                  onClick={() => navigateToTab(index)}
                >
                  <span>{t(tab.title)}</span>
                </div>
              );
            })}
          </FlexView>
          {activeTabIndex === 0 && <ProductDetails productName={productName} />}
          {activeTabIndex === 1 && <ProductFlow productName={productName} />}
        </FlexView>
        <Footer />
      </FlexView>
    </PageLayout>
  );
};

export default TeamAssessment;
