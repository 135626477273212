import { UserService } from '@zortify/react-keycloak';
import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';

import zortifyLogo from '../images/zortify_logo_signin.svg';
import styles from '../styles/Login.module.css';
import Footer from './Footer';
import PageLayout from './PageLayout';
import Button from './UI/Button';

const LoginComponent = () => {
  let { t } = useTranslation();

  function loginClickHandler({ nativeEvent }) {
    nativeEvent.preventDefault();
    UserService.doLogin();
  }

  const getLoginButtonProps = () => {
    let metadata = {
      text: t('welcome.sign-in'),
      click: loginClickHandler,
    };
    return metadata;
  };

  const customFooterStyles = {
    container: styles.login_footer_custom_style,
  };

  return (
    <PageLayout hideNav={true}>
      <FlexView className={styles.login_container}>
        <FlexView className={styles.login_sidebar_container}>
          <img src={zortifyLogo} className={styles.login_sidebar_logo} alt="zortifyLogo" />
        </FlexView>
        <FlexView className={styles.login_frame_container} column>
          <FlexView className={styles.login_form_container} column>
            <span className={styles.login_form_title_span}>{t('welcome.title')}</span>
            <form onSubmit={loginClickHandler} className={styles.login_form}>
              <div className={styles.login_button_container}>
                <Button metadata={getLoginButtonProps()} />
              </div>
            </form>
          </FlexView>
          <Footer customStyles={customFooterStyles} />
        </FlexView>
      </FlexView>
    </PageLayout>
  );
};

export default LoginComponent;
