import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';

import styles from './PrivacyPolicy.module.css';

const CookieDataProcessingForMarketing = () => {
  let { t } = useTranslation();
  return (
    <FlexView className={styles.general_container} column>
      <span>{t('privacy.cookie-data-processing-for-marketing.text1')}</span>
      <br />
      <span>{t('privacy.cookie-data-processing-for-marketing.text2')}</span>
      <ul>
        <li>HubSpot</li>
        <li>Google Analytics</li>
        <li>Bing Analytics</li>
      </ul>
      <br />
      <span>{t('privacy.cookie-data-processing-for-marketing.text3')}</span>
      <span>{t('privacy.cookie-data-processing-for-marketing.text4')}</span>
      <span>{t('privacy.cookie-data-processing-for-marketing.text5')}</span>
      <span>{t('privacy.cookie-data-processing-for-marketing.text6')}</span>
    </FlexView>
  );
};

export default CookieDataProcessingForMarketing;
