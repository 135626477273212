import clsx from 'clsx';
import { useContext } from 'react';

import ThemeContext from '../../contexts/ThemeContext';
import styles from './Avatar.module.css';

const Avatar = (props) => {
  const { theme } = useContext(ThemeContext);

  const initials = props.alt ? props.alt[0] : '';
  const className = clsx(styles.alt_text, styles['avatar_' + theme], props.alt_text_style);
  return (
    <>
      {props.src && <img src={props.src} alt={props.alt} />}
      {!props.src && <div className={className}>{initials}</div>}
    </>
  );
};

export default Avatar;
