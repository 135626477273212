import './index.css';
import './i18n/config';

import { UserService } from '@zortify/react-keycloak';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import RouteResolve from './components/RouteResolve';
import { store } from './store';

function startApp() {
  let root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <Provider store={store}>
      <RouteResolve />
    </Provider>,
  );
}

// Initializes Keycloak, then calls the startApp function
UserService.init(startApp);
