import PropTypes from 'prop-types';
import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';

import importantNote from '../images/important_note.svg';
import styles from '../styles/EmptyPage.module.css';

const EmptyPage = (props) => {
  const { t } = useTranslation();
  return (
    <FlexView column className={styles.empty_page_container}>
      <img
        src={importantNote}
        alt="importantNote"
        className={styles.empty_page_important_note_icon}
      />
      <span className={styles.empty_page_message}>{t(props.message)}</span>
      <br />
    </FlexView>
  );
};

EmptyPage.propTypes = {
  message: PropTypes.string,
};

// Specifies the default values for props:
EmptyPage.defaultProps = {
  message: 'error.no-contents-to-display',
};

export default EmptyPage;
