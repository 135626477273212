import styles from './Users.module.css';

export const STATUS = {
  ENABLED: {
    name: 'ENABLED',
    value: 0,
    style: {
      container: styles.enabled_container,
      span: styles.enabled_span,
    },
  },
  DISABLED: {
    name: 'DISABLED',
    value: 1,
    style: {
      container: styles.disabled_container,
      span: styles.disabled_span,
    },
  },
};
