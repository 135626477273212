import { useEffect, useState } from 'react';

//Define the page range (no. of pages required for the given data)
export const calculateRange = (dataLength, rowsPerPage) => {
  const range = [];
  const num = Math.ceil(dataLength / rowsPerPage);
  for (let i = 1; i <= num; i++) {
    range.push(i);
  }
  return range;
};

//Obtain data for a particular page.
const sliceData = (data, page, rowsPerPage) => {
  return data.slice((page - 1) * rowsPerPage, page * rowsPerPage);
};

const useTable = (data, dataLength, page, rowsPerPage) => {
  const [tableRange, setTableRange] = useState([]);
  const [slice, setSlice] = useState([]);

  useEffect(() => {
    const range = calculateRange(dataLength, rowsPerPage);
    setTableRange([...range]);

    const slice = sliceData(data, page, rowsPerPage);
    setSlice([...slice]);
  }, [data, dataLength, setTableRange, page, setSlice]); // eslint-disable-line react-hooks/exhaustive-deps

  return { slice, range: tableRange };
};

export default useTable;
