import { useEffect, useState } from 'react';
import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import EmptyPage from '../../components/EmptyPage';
import Table from '../../components/UI/Table';
import { STATE_PARAMS } from '../../consts/CommonConstants';
import httpMessenger from '../../services/HTTPMessenger';
import localStorageService from '../../Shared/LocalStorageService';
import Spinner from '../../Shared/Spinner';
import styles from './SurveyMapping.module.css';

export const ColumnHeaders = [
  { title: 'PRODUCT NAME' },
  { title: 'LANGUAGE' },
  { title: 'SURVEY ID' },
];

const SurveyMapping = ({ Id, cName }) => {
  const { t } = useTranslation();
  const [surveyMapping, setSurveyMapping] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const localStorageStateParams =
    JSON.parse(localStorageService.getLocalStorageValue(STATE_PARAMS)) || {};

  useEffect(() => {
    const companyId = Id || localStorageStateParams.companyId;
    const companyName = cName || localStorageStateParams.companyName;
    localStorageService.setLocalStorageValue(
      STATE_PARAMS,
      JSON.stringify(Object.assign({}, { companyId, companyName })),
    );
    loadSurveyMappings();
  }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      localStorageService.deleteLocalStorageValue(STATE_PARAMS);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadSurveyMappings = async () => {
    setIsLoading(true);
    const httpResult = await httpMessenger.apiCall('GET', 'surveyMapping', null, null, null, [Id]);
    setIsLoading(false);
    if (httpResult.success) {
      if (httpResult.result.length === 0)
        setErrorMessage('You still have no surveys for this company.');
      else {
        let rows = [];
        httpResult.result.forEach((survey) => {
          rows.push({
            id: survey.id,
            data: [
              { content: survey.product },
              { content: survey.lang },
              { content: survey.surveyId },
            ],
          });
        });
        setSurveyMapping([...rows]);
      }
    } else {
      let errorMessage = [];
      httpResult.error.forEach((error) => {
        errorMessage.push(t(error.key, error.parameters));
      });
      setErrorMessage(errorMessage.join(' '));
    }
  };

  return (
    <FlexView className={styles.container} column>
      {!isLoading && surveyMapping.length > 0 && (
        <Table theadData={ColumnHeaders} tbodyData={surveyMapping} />
      )}
      {isLoading && <Spinner />}
      {!isLoading && surveyMapping.length <= 0 && <EmptyPage message={errorMessage} />}
    </FlexView>
  );
};
export default SurveyMapping;
