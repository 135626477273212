import FlexView from 'react-flexview/lib';
import { Trans, useTranslation } from 'react-i18next';

import styles from './PrivacyPolicy.module.css';

const DataDeletion = () => {
  let { t } = useTranslation();
  return (
    <FlexView className={styles.general_container} column>
      <span>{t('privacy.deletion-of-data.text1')}</span>
      <br />
      <span>
        <Trans
          i18nKey="privacy.deletion-of-data.text2"
          components={{
            1: (
              <a href="mailto:privacy@zortify.com" className={styles.general_info_href}>
                {' '}
              </a>
            ),
          }}
        />
      </span>
    </FlexView>
  );
};

export default DataDeletion;
