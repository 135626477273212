import React from 'react';
import FlexView from 'react-flexview';
import { useTranslation } from 'react-i18next';

import Chip from '../components/UI/Chip';
import styles from './TeamAssessment.module.css';

const teamDetails = [
  {
    topic: 'team-assessment.topic.concept',
    details: [
      {
        title: 'team.topic.concept.title1',
        description: 'team.topic.concept.description1',
      },
    ],
  },
  {
    topic: 'team-assessment.topic.methodology',
    details: [
      {
        title: 'team.topic.methodology.title1',
        description: 'team.topic.methodology.description1',
      },
    ],
  },
  {
    topic: 'team-assessment.topic.outcomes',
    details: [
      {
        title: 'team.topic.outcomes.title1',
        description: 'team.topic.outcomes.description1',
      },
      {
        title: 'team.topic.outcomes.title2',
        description: 'team.topic.outcomes.description2',
      },
      {
        title: 'team.topic.outcomes.title3',
        description: 'team.topic.outcomes.description3',
      },
      {
        title: 'team.topic.outcomes.title4',
        description: 'team.topic.outcomes.description4',
      },
    ],
  },
];

const leaderDetails = [
  {
    topic: 'team-assessment.topic.concept',
    details: [
      {
        title: 'leader.topic.concept.title1',
        description: 'leader.topic.concept.description1',
      },
    ],
  },
  {
    topic: 'team-assessment.topic.methodology',
    details: [
      {
        title: 'leader.topic.methodology.title1',
        description: 'leader.topic.methodology.description1',
      },
    ],
  },
  {
    topic: 'team-assessment.topic.outcomes',
    details: [
      {
        title: 'leader.topic.outcomes.title1',
        description: 'leader.topic.outcomes.description1',
      },
      {
        title: 'leader.topic.outcomes.title2',
        description: 'leader.topic.outcomes.description2',
      },
      {
        title: 'leader.topic.outcomes.title3',
        description: 'leader.topic.outcomes.description3',
      },
      {
        title: 'leader.topic.outcomes.title4',
        description: 'leader.topic.outcomes.description4',
      },
    ],
  },
];

const ProductDetails = ({ productName }) => {
  const { t } = useTranslation();
  const productDetails = productName === 'Zortify Leader' ? leaderDetails : teamDetails;
  const getChipMetadata = (text) => {
    return {
      text: t(text),
      style: {
        container: styles.chip_container,
        span: styles.chip_span,
      },
    };
  };

  return (
    <FlexView className={styles.product_details_container} column>
      {productDetails.map((productDetail) => {
        return (
          <FlexView className={styles.product_details_element_container}>
            <FlexView className={styles.product_details_left_container}>
              <Chip metadata={getChipMetadata(productDetail.topic)} />
            </FlexView>
            <FlexView className={styles.product_details_right_container} column>
              {productDetail.details.map((detail) => {
                return (
                  <FlexView column className={styles.product_details_topic_container}>
                    <span className={styles.product_details_topic_title}>{t(detail.title)}</span>
                    <span className={styles.product_details_topic_description}>
                      {t(detail.description)}
                    </span>
                  </FlexView>
                );
              })}
            </FlexView>
          </FlexView>
        );
      })}
    </FlexView>
  );
};

export default ProductDetails;
