import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';

import styles from './PrivacyPolicy.module.css';

const GeneralInfo = () => {
  const { t } = useTranslation();

  return (
    <FlexView className={styles.general_container} column>
      <span>{t('privacy.general-info.text1')}</span>
      <span>
        <i>
          <b>{t('privacy.general-info.text2')}</b>
        </i>
      </span>
      <br />
      <>
        <span>
          <b>1.1 </b>
          {t('privacy.general-info.text3')}
        </span>
        <span>Zortify S.A.</span>
        <span>{t('privacy.general-info.text5')}</span>
        <span>9, Rue du Laboratoire</span>
        <span>L-1911 Luxembourg, Luxembourg</span>
        <span>
          e-mail:{' '}
          <a href="mailto:hello@zortify.com" className={styles.general_info_href}>
            hello@zortify.com
          </a>
        </span>
        <br />
        <span>
          <b>1.2 </b>
          {t('privacy.general-info.text4')}
        </span>
        <span>{t('privacy.general-info.text6')}</span>
        <span>Zortify S.A.</span>
        <span>{t('privacy.general-info.text5')}</span>
        <span>9, Rue du Laboratoire</span>
        <span>L-1911 Luxembourg, Luxembourg</span>
        <span>
          e-mail:{' '}
          <a href="mailto:privacy@zortify.com" className={styles.general_info_href}>
            privacy@zortify.com
          </a>
        </span>
      </>
    </FlexView>
  );
};

export default GeneralInfo;
