import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { NULL, UNDEFINED } from '../../../consts/CommonConstants';
import ThemeContext from '../../../contexts/ThemeContext';
import Button from '../Button';
import Dropdown from '../Dropdown';
import styles from './TableFooter.module.css';

const TableFooter = ({ range, setPage, page, slice, itemsPerPageData }) => {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const itemsPerPageDropDownMetaData =
    itemsPerPageData && itemsPerPageData.metadata
      ? itemsPerPageData.metadata
      : {
          defaultValue: {
            code: 10,
            label: '10',
          },
          options: [
            {
              code: 10,
              label: '10',
            },
            {
              code: 50,
              label: '50',
            },
            {
              code: 100,
              label: '100',
            },
          ],
          additionalStyles: {
            container: styles.items_per_page_dropdown_additional_style,
            label: styles.items_per_page_dropdown_label_additional_style,
          },
        };

  const itemsPerPageChangeHandler = (newPageSize) => {
    if (itemsPerPageData !== UNDEFINED && newPageSize !== NULL) {
      itemsPerPageData.callback(newPageSize);
      setPage(1, true);
    }
  };

  const prevMetadata = {
    text: t('general.prev'),
    click: () => setPage(page - 1),
    style: styles.prev_next_active_button,
    leftIcon: 'arrow_left.svg',
  };
  const nextMetadata = {
    text: t('general.next'),
    click: () => setPage(page + 1),
    style: styles.prev_next_active_button,
    rightIcon: 'arrow_right.svg',
  };

  useEffect(() => {
    if (slice.length < 1 && page !== 1) {
      setPage(page - 1);
    }
  }, [slice, page, setPage]);

  const checkButtonVisibility = (pageToCheck, currentPage) => {
    let startPage = currentPage - 5;
    let endPage = currentPage + 4;

    if (currentPage === 1) {
      startPage = 1;
      endPage = 10; //Show max 10 pages
    } else if (currentPage === range[range.length - 1]) {
      //last page
      startPage = range.length - 9;
      endPage = range.length;
    }

    if (startPage <= 0) {
      endPage -= startPage - 1;
      startPage = 1;
    }

    if (endPage > range.length) {
      startPage -= endPage - range.length;
      endPage = range.length;
    }

    //make sure again for out of range
    if (startPage <= 0) {
      startPage = 1;
    }

    if (pageToCheck >= startPage && pageToCheck <= endPage) return true;
    else return false;
  };

  return (
    <div className={styles.tableFooter}>
      <Button key="prev_button" disabled={page === 1} metadata={prevMetadata} />
      {range.map((el, index) => {
        const metadata = {
          text: el,
          click: () => setPage(el),
          style: `${styles.button} ${
            page === el ? styles['activeButton_' + theme] : styles.inactiveButton
          }`,
        };
        return checkButtonVisibility(el, page) && <Button key={index} metadata={metadata} />;
      })}
      <Button
        key="next_button"
        disabled={page === range[range.length - 1]}
        metadata={nextMetadata}
      />
      <div className={styles.items_per_page}>
        <label className={styles.filter_label}>{t('general.items-per-page')}</label>
        <Dropdown {...itemsPerPageDropDownMetaData} callback={itemsPerPageChangeHandler} />
      </div>
    </div>
  );
};

export default TableFooter;
