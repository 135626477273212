import FlexView from 'react-flexview';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';

import zortifyLogo from '../images/zortify_logo_external.svg';
import PageLayout from './PageLayout';
import styles from './PageNotFound.module.css';
import Button from './UI/Button';

const PageNotFound = () => {
  let { t } = useTranslation();
  const navigate = useNavigate();

  const goToMainPage = () => {
    navigate('/');
  };

  const getButtonMetadata = () => {
    return {
      text: t('general.go-back'),
      click: goToMainPage,
      // style: styles.add_more_button_style,
    };
  };
  return (
    <PageLayout hideNav={true}>
      <FlexView className={styles.main_container} column>
        <img src={zortifyLogo} className={styles.logo} />
        <span className={styles.four_o_four}>404</span>
        <span className={styles.page_not_found_title}>{t('error.page-not-found')}</span>
        <span className={styles.page_not_found_description}>
          {t('error.page-not-found-description')}
        </span>
        <Button metadata={getButtonMetadata()} />
      </FlexView>
    </PageLayout>
  );
};

export default PageNotFound;
