import React from 'react';
import FlexView from 'react-flexview/lib';

import ArrowDown from '../../images/arrow_down.svg';
import ArrowUp from '../../images/arrow_up.svg';
import DomRender from '../DomRender';
import styles from './TableRow.module.css';

export const RecursiveRow = ({ rowData, columns, isChild }) => {
  const hasChildren = rowData && rowData.children && rowData.children.length > 0;
  const actions = rowData.actions || [];
  const [showChildren, setShowChildren] = React.useState(true);

  const toggleShowChildren = () => {
    setShowChildren(!showChildren);
  };
  return (
    <>
      <tr className={styles.table_tr}>
        {hasChildren && (
          <td>
            <img
              style={{ cursor: 'pointer' }}
              src={showChildren ? ArrowUp : ArrowDown}
              alt="showDetails"
              onClick={toggleShowChildren}
            />
          </td>
        )}

        {rowData.data.map((item, index) => {
          return (
            <td key={'column_' + rowData.id + '_' + index} className={item.additionalStyle}>
              {item.content}
            </td>
          );
        })}

        {actions.content && actions.content.length > 0 && (
          <td className={actions.additionalStyle}>
            <FlexView className={styles.table_row_actions_container}>
              {actions.content.map((action, index) => (
                <DomRender
                  elemType={action.elemType}
                  key={'action_' + rowData.id + '_' + index}
                  metadata={action.metadata}
                  {...action.props}
                />
              ))}
            </FlexView>
          </td>
        )}
      </tr>
      {hasChildren &&
        showChildren &&
        rowData.children.map((child) => (
          <RecursiveRow key={'row_' + child.id} rowData={child} columns={columns} />
        ))}
    </>
  );
};

export default RecursiveRow;
