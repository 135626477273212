import { COMPARISON_SIGNS } from '../consts/CommonConstants';
import httpMessenger from '../services/HTTPMessenger';

export default class CreateInvitationsPresenter {
  getAvailableStockConfig = (availableInvitations, productName, pricePerInvitation) => {
    return {
      label: 'general.amount-of-invitations',
      label_hint: 'create.invitation.cost-per-invitation',
      label_hint_params: {
        product: productName,
        cost: pricePerInvitation,
      },
      type: 'text',
      code: 'amount_of_invitations',
      hint: 'create.invitations.max-available-invitations',
      hint_params: {
        availableInvitations: availableInvitations,
      }, //`The maximum amount of available invitations is ${availableInvitations}.`,
      instantValidations: [
        {
          type: 'validateType',
          attributes: {
            type: 'number',
            errorMessage: 'error.value.should-be-number',
          },
        },
        {
          type: 'valueCompare',
          attributes: {
            compareSign: COMPARISON_SIGNS.GT,
            expectation: 0,
            errorMessage: 'error.value.invalid-units',
          },
        },
        {
          type: 'valueCompare',
          attributes: {
            compareSign: COMPARISON_SIGNS.LTEQ,
            expectation: 100,
            errorMessage: 'error.value.units-gt-100',
          },
        },
        {
          type: 'valueCompare',
          attributes: {
            compareSign: COMPARISON_SIGNS.LTEQ,
            expectation: availableInvitations,
            errorMessage: 'error.value.units-gt-x',
            errorMessageParams: { max: availableInvitations },
          },
        },
      ],
      submitValidations: [
        {
          type: 'required',
          attributes: {},
        },
      ],
      options: null,
    };
  };

  createInvitations = async (body) => {
    const httpResult = await httpMessenger.apiCall('POST', 'invitation', body);
    if (httpResult.success) {
      return {
        success: true,
        result: httpResult.result,
      };
    } else {
      return {
        success: false,
        error: httpResult.error,
      };
    }
  };
}
