import styles from './Status.module.css';

export const STATUS = {
  CREATED: {
    key: 'CREATED',
    name: 'general.created',
    value: 0,
    style: {
      container: styles.created_container,
      span: styles.created_span,
    },
  },
  COPIED: {
    key: 'COPIED',
    name: 'general.copied',
    value: 1,
    style: {
      container: styles.copied_container,
      span: styles.copied_span,
    },
  },
  REGISTERED: {
    key: 'REGISTERED',
    name: 'general.registered',
    value: 2,
    style: {
      container: styles.registered_container,
      span: styles.registered_span,
    },
  },
  FINISHED: {
    key: 'FINISHED',
    name: 'general.finished',
    value: 3,
    style: {
      container: styles.finished_container,
      span: styles.finished_span,
    },
  },
  COMPLETED: {
    key: 'COMPLETED',
    name: 'general.completed',
    value: 4,
    style: {
      container: styles.completed_container,
      span: styles.completed_span,
    },
  },
  EXPIRED: {
    key: 'EXPIRED',
    name: 'general.expired',
    value: 5,
    style: {
      container: styles.expired_container,
      span: styles.expired_span,
    },
  },
};
