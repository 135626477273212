import FlexView from 'react-flexview/lib';

import styles from './LegalDisclosure.module.css';

const BoardOfDirectors = () => {
  return (
    <FlexView className={styles.general_container} column>
      <span>Prof. Dr. Florian Feltes</span>
      <span>Dr. Marcus Heidbrink</span>
    </FlexView>
  );
};

export default BoardOfDirectors;
