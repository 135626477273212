import FlexView from 'react-flexview/lib';

import styles from './LegalDisclosure.module.css';

const CompanyRegisterationNumber = () => {
  return (
    <FlexView className={styles.general_container} column>
      <span>RCSL N° B228166</span>
    </FlexView>
  );
};

export default CompanyRegisterationNumber;
