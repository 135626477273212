import { useEffect, useRef, useState } from 'react';
import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import PageLayout from '../components/PageLayout';
import iIcon from '../images/i-icon.svg';
import ComfortFunctions from './ComfortFunctions';
import ContactAndRightOfAppeal from './ContactAndRightOfAppeal';
import CookieDataProcessingForMarketing from './CookieDataProcessingForMarketing';
import Cookies from './Cookies';
import DataDeletion from './DataDeletion';
import DataSubjectRights from './DataSubjectRights';
import DirectCorrespondence from './DirectCorrespondence';
import GeneralInfo from './GeneralInfo';
import MarketAndIndustrialResearch from './MarketAndIndustrialResearch';
import PrivacyHeader from './PrivacyHeader';
import styles from './PrivacyPolicy.module.css';
import PrivacyPolicyChanges from './PrivacyPolicyChanges';
import UseOfZortifyAnalysis from './UseOfZortifyAnalysis';
import VisitingOurWebsite from './VisitingOurWebsite';

const privacyConfig = [
  {
    title: 'privacy.general-info.title',
    displayTitle: 'privacy.general-info.display-title',
    content: <GeneralInfo />,
  },
  {
    title: 'privacy.visit-website.title',
    content: <VisitingOurWebsite />,
  },
  {
    title: 'privacy.cookies.title',
    content: <Cookies />,
  },
  {
    title: 'privacy.cookie-data-processing-for-marketing.title',
    content: <CookieDataProcessingForMarketing />,
  },
  {
    title: 'privacy.comfort-functions.title',
    content: <ComfortFunctions />,
  },
  {
    title: 'privacy.use-of-zortify-analysis.title',
    content: <UseOfZortifyAnalysis />,
  },
  {
    title: 'privacy.market-and-industrial-research.title',
    content: <MarketAndIndustrialResearch />,
  },
  {
    title: 'privacy.direct-correspondence.title',
    content: <DirectCorrespondence />,
  },
  {
    title: 'privacy.data-subject-rights.title',
    content: <DataSubjectRights />,
  },
  {
    title: 'privacy.deletion-of-data.title',
    content: <DataDeletion />,
  },
  {
    title: 'privacy.contact-and-right-of-appeal.title',
    content: <ContactAndRightOfAppeal />,
  },
  {
    title: 'privacy.privacy-policy-changes.title',
    content: <PrivacyPolicyChanges />,
  },
];

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  const [activeTitle, setActiveTitle] = useState(null);
  const titleRefs = useRef(null);
  const [privacyContent, setPrivacyContent] = useState({ data: [] });
  const { lang } = useParams();

  useEffect(() => {
    let privacyContentVm = getContent();
    setPrivacyContent(privacyContentVm);
    if (privacyContentVm && privacyContentVm.data) {
      setActiveTitle(privacyContentVm.data[0].title);
    }
  }, []);

  const getCustomSpanStyle = (title) => {
    const style =
      title === activeTitle
        ? `${styles.nav_menu_display_span} ${styles.nav_active_display_span}`
        : styles.nav_menu_display_span;
    return style;
  };

  const titleClickHandler = (item) => {
    setActiveTitle(item.title);
    const map = getMap();
    const node = map.get(item.title);
    node.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const setTitlesRef = (node, item) => {
    const map = getMap();
    if (node) {
      map.set(item.title, node);
    } else {
      map.delete(item.title);
    }
  };

  function getMap() {
    if (!titleRefs.current) {
      // Initialize the Map on first usage.
      titleRefs.current = new Map();
    }
    return titleRefs.current;
  }

  const getContent = () => {
    let privacyContentsVm = { data: [] };
    privacyConfig.map((item) => {
      privacyContentsVm.data.push({
        title: item.title,
        displayTitle: item.displayTitle || item.title,
        content: item.content,
      });
      return true;
    });
    return privacyContentsVm;
  };

  return (
    <PageLayout hideNav>
      <FlexView className={styles.base_container} column>
        <PrivacyHeader
          title={t('legal-disclosure.title')}
          href="/legaldisclosure"
          externalPreferredLanguage={lang}
        />

        <FlexView className={styles.base_container}>
          <FlexView className={styles.menu_container} column>
            {privacyContent.data.map((item, key) => (
              <FlexView className={styles.nav_individual_menu_container} key={key}>
                <span
                  className={getCustomSpanStyle(item.title)}
                  onClick={() => titleClickHandler(item)}
                >
                  {t(item.title)}
                </span>
              </FlexView>
            ))}
          </FlexView>
          <FlexView className={styles.content_container} column>
            <span className={styles.privacy_policy_title}>{t('privacy.title')} (v1.4)</span>
            <FlexView className={styles.revised_date_container}>
              <img src={iIcon} className={styles.i_icon} alt={'revised-date'} />
              <span>
                <b>{t('privacy.revised-date')} </b>
                October 06 2023
              </span>
            </FlexView>

            {privacyContent.data.map((item, key) => (
              <FlexView
                ref={(node) => setTitlesRef(node, item)}
                className={styles.nav_individual_content_container}
                column
                key={key}
              >
                <span className={styles.display_title}>{t(item.displayTitle)}</span>
                {item.content}
              </FlexView>
            ))}
          </FlexView>
        </FlexView>
      </FlexView>
    </PageLayout>
  );
};

export default PrivacyPolicy;
