import { useEffect, useState } from 'react';
import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setInvitationsContext } from '../actions/InvitationsActions';
import EmptyPage from '../components/EmptyPage';
import Footer from '../components/Footer';
import Header from '../components/Header';
import PageLayout from '../components/PageLayout';
import Button from '../components/UI/Button';
import Modal from '../components/UI/Modal';
import { NULL, UNDEFINED } from '../consts/CommonConstants';
import failureIcon from '../images/important_note.svg';
import successTick from '../images/success_tick_external.svg';
import httpMessenger from '../services/HTTPMessenger';
import Spinner from '../Shared/Spinner';
import styles from './CreateInvitations.module.css';
import CreateInvitationsPresenter from './CreateInvitationsPresenter';
import FormElement from './FormElement';

const CreateInvitations = () => {
  const { t } = useTranslation();
  const invitationsContext = useSelector((state) => state.invitations.context);
  const [error, setError] = useState(false);
  const [productDetails, setProductDetails] = useState({});
  const [amountOfInvitations, setAmountOfInvitations] = useState(0);
  const [availableStockConfig, setAvailableStockConfig] = useState({});
  const [trigger, setTrigger] = useState(false);
  const [isFirst, setIsFirst] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [elementsValidity, setElementsValidity] = useState({
    count: 0,
    allElementsValid: true,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    show: false,
  });
  const [config, setConfig] = useState({});
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const createInvitationsPresenter = new CreateInvitationsPresenter();

  const customFooterStyles = {
    container: styles.footer_custom_style,
  };
  const customHeaderStyles = {
    headerLine: styles.header_custom_style,
  };

  const getProductConfigByName = (result, productName) => {
    const productConfigVm = {};
    if (result !== UNDEFINED) {
      const product = result.find((product) => product.name === productName);
      const config = product.config;
      if (config !== UNDEFINED) {
        try {
          productConfigVm.label = config.label;
          productConfigVm.code = config.code;
          productConfigVm.type = config.type;
          productConfigVm.data = {
            code: config.options[0].code,
            label: config.options[0].label,
          };
          const attributes = [...config.options[0].attributes];
          productConfigVm.attributes = [...attributes];
        } catch (err) {
          console.log(err);
          return UNDEFINED;
        }
      } else return UNDEFINED;
    } else return UNDEFINED;
    return productConfigVm;
  };

  const loadProducts = async () => {
    setIsLoading(true);
    const httpResult = await httpMessenger.apiCall('GET', 'products');
    setIsLoading(false);
    if (httpResult.success) {
      if (httpResult.result) {
        let productsVm = getProductConfigByName(httpResult.result, invitationsContext.productName);
        if (productsVm !== UNDEFINED) {
          setProductDetails(productsVm);
          const stockConfig = createInvitationsPresenter.getAvailableStockConfig(
            Math.floor(invitationsContext.availableUnits / invitationsContext.pricePerInvitation),
            invitationsContext.productName,
            invitationsContext.pricePerInvitation,
          );
          setAvailableStockConfig(stockConfig);
          updateConfig(productsVm.code, productsVm.data.code, true);
        } else {
          setError(true);
        }
      }
    } else {
      let errorMessage = [];
      httpResult.error.forEach((error) => {
        errorMessage.push(t(error.key, error.parameters));
      });
      setError(true);
      setErrorMessage(errorMessage.join(' '));
    }
  };

  const getCancelButtonProps = () => {
    let metadata = {
      text: t('general.cancel'),
      click: cancelCreateInvitations,
      style: styles.cancel_button_style,
    };
    return metadata;
  };

  const getSubmitButtonProps = () => {
    let metadata = {
      text: t('user.dashboard.create-invitations'),
      click: createInvitations,
      style: styles.submit_button_style,
    };
    return metadata;
  };

  const cancelCreateInvitations = () => {
    window.history.back();
  };

  useEffect(() => {
    if (!isFirst) sendCreateInvitationRequest();
    else setIsFirst(false);
  }, [elementsValidity]);

  const findNumberOfElementsBeforeSubmit = (data) => {
    let numberOfValidElements = 0;
    if (data && data.options) {
      data.options.forEach((option) => {
        if (option.code === getAttributeConfig(data.code) && option.attributes) {
          numberOfValidElements += option.attributes.length;
          option.attributes.forEach((attribute) => {
            numberOfValidElements += findNumberOfElementsBeforeSubmit(attribute);
          });
        }
      });
    }
    return numberOfValidElements;
  };

  const sendCreateInvitationRequest = async () => {
    let numberOfValidElementsBeforeSubmit = productDetails.attributes.length + 1; //+1 for number of invitations
    if (productDetails.attributes) {
      productDetails.attributes.forEach((data) => {
        numberOfValidElementsBeforeSubmit += findNumberOfElementsBeforeSubmit(data);
      });
    }

    if (elementsValidity.count === numberOfValidElementsBeforeSubmit) {
      //Validations for all form elements complete
      //+1 is for amount of invitations validity
      if (elementsValidity.allElementsValid) {
        setIsLoading(true);
        setButtonDisabled(true);
        let finalConfig = { ...config };
        if (doesContainPositionAttribute() && !finalConfig['position']) finalConfig.position = '';
        const body = {
          planId: invitationsContext.planId,
          productId: invitationsContext.productId,
          config: finalConfig,
          amount: amountOfInvitations,
        };

        const response = await createInvitationsPresenter.createInvitations(body);
        setIsLoading(false);
        setButtonDisabled(false);
        if (response.success) {
          showDialog(true);
        } else {
          let errorMessage = [];
          response.error.forEach((error) => {
            errorMessage.push(t(error.key, error.parameters));
          });
          showDialog(false, errorMessage.join(' '));
        }
      }
    }
  };
  const doesContainPositionAttribute = () => {
    //This is a workaround to avoid error in creating invitation if position is missing from config.
    if (productDetails && productDetails.attributes) {
      let containsPositionAttribute = productDetails.attributes.findIndex(
        (attribute) => attribute && attribute.code === 'position',
      );
      if (containsPositionAttribute > -1) return true;
    }
    return false;
  };

  const createInvitations = () => {
    setElementsValidity({ count: 0, allElementsValid: true });
    setTrigger(!trigger);
  };

  const showDialog = (success, errorMessage) => {
    if (success) {
      setModalInfo({
        show: true,
        text:
          t('create.invitation.link-creation-successful', {
            amountOfInvitations: amountOfInvitations,
          }) +
          ' ' +
          t('create.invitation.check-product-details'),
        formActions: {
          rightButton: {
            text: 'Close',
            action: successModalClose,
          },
        },
        close: successModalClose,
        icon: successTick,
      });
    } else {
      setModalInfo({
        show: true,
        text:
          errorMessage ||
          t('error.message.something-went-wrong') + ' ' + t('error.message.please-try-later'),
        formActions: {
          rightButton: {
            text: 'Close',
            action: failureModalClose,
          },
        },
        close: failureModalClose,
        icon: failureIcon,
      });
    }
  };

  const successModalClose = () => {
    setModalInfo({
      show: false,
      text: null,
      formActions: {
        rightButton: {
          text: null,
          action: null,
        },
      },
      icon: null,
    });

    //Set remaining units after invitation creation which is not reflected after API success.
    const remainingUnits =
      invitationsContext.availableUnits -
      amountOfInvitations * invitationsContext.pricePerInvitation;

    dispatch(
      setInvitationsContext({
        ...invitationsContext,
        availableUnits: remainingUnits,
      }),
    );
    navigate(`/createdinvitations`);
  };

  const failureModalClose = () => {
    setModalInfo({
      show: false,
      text: null,
      formActions: {
        rightButton: {
          text: null,
          action: null,
        },
      },
      icon: null,
    });
  };

  const updateConfig = (code, value, isValid) => {
    if (code === 'amount_of_invitations') {
      setAmountOfInvitations(value);
    } else {
      setConfig((currentConfig) => {
        let updatedConfig = { ...currentConfig };
        if (value === NULL || value === UNDEFINED || (value === '' && code !== 'position')) {
          delete updatedConfig[code];
        } else {
          updatedConfig[code] = value;
        }
        return updatedConfig;
      });
    }
  };

  const handleSubmitValidation = async (isValid) => {
    //isValid is the validation for one of the form elements in the loop.
    setElementsValidity((prevElementsValidity) => ({
      count: prevElementsValidity.count + 1,
      allElementsValid: prevElementsValidity.allElementsValid && isValid,
    }));
  };

  const getAttributeConfig = (code) => {
    return config[code]; //config[attribute.metadata.code]
  };

  useEffect(() => {
    if (!invitationsContext) {
      navigate('/dashboard');
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (invitationsContext) {
      loadProducts();
    }
  }, [invitationsContext]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!invitationsContext) {
    return null;
  }

  return (
    <PageLayout>
      <FlexView column className={styles.createinvitations_container}>
        <Header title={t('user.dashboard.create-invitations')} customStyles={customHeaderStyles} />
        {!error && (
          <FlexView className={styles.createinvitations_content_container} column>
            <FlexView column className={styles.createinvitations_form_container}>
              <FormElement
                type={productDetails.type}
                label={productDetails.label}
                labelHint={productDetails.label_hint}
                data={productDetails.data}
                trigger={trigger}
                attributeConfig={getAttributeConfig}
                callback={updateConfig}
              />
              {productDetails.attributes &&
                productDetails.attributes.map((attribute, index) => {
                  return (
                    <>
                      <FormElement
                        key={index}
                        type={attribute.type}
                        label={attribute.label}
                        labelHint={attribute.label_hint}
                        data={attribute}
                        trigger={trigger}
                        submitValidation={handleSubmitValidation}
                        attributeConfig={getAttributeConfig}
                        callback={updateConfig}
                      />
                    </>
                  );
                })}
              <FormElement
                type={availableStockConfig.type}
                label={availableStockConfig.label}
                labelHint={availableStockConfig.label_hint}
                labelHintParams={availableStockConfig.label_hint_params}
                data={availableStockConfig}
                trigger={trigger}
                submitValidation={handleSubmitValidation}
                attributeConfig={getAttributeConfig}
                callback={updateConfig}
              />
            </FlexView>

            <FlexView className={styles.createinvitations_footer_container}>
              <Button metadata={getCancelButtonProps()} />
              <Button metadata={getSubmitButtonProps()} disabled={isButtonDisabled} />
            </FlexView>
          </FlexView>
        )}
        {isLoading && <Spinner />}
        {error && (
          <EmptyPage
            message={
              errorMessage ||
              t('error.message.something-went-wrong') + ' ' + t('error.message.please-try-later')
            }
          />
        )}
        {modalInfo.show && (
          <Modal onClose={modalInfo.close} formActions={modalInfo.formActions}>
            <FlexView column className={styles.modal_content_container}>
              <img src={modalInfo.icon} className={styles.modal_content_icon} alt="modal_icon" />
              <span className={styles.modal_content_text}>{modalInfo.text}</span>
            </FlexView>
          </Modal>
        )}
        <Footer customStyles={customFooterStyles} />
      </FlexView>
    </PageLayout>
  );
};
export default CreateInvitations;
