import { useEffect, useState } from 'react';
import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import DomRender from '../../components/DomRender';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import PageLayout from '../../components/PageLayout';
import Button from '../../components/UI/Button';
import Modal from '../../components/UI/Modal';
import failureIcon from '../../images/important_note.svg';
import successTickInternal from '../../images/success_tick_internal.svg';
import httpMessenger from '../../services/HTTPMessenger';
import Spinner from '../../Shared/Spinner';
import validationService from '../../Shared/ValidationService';
import styles from './NewPlan.module.css';
import NewPlanPresenter from './NewPlanPresenter';

const NewPlan = () => {
  const { t } = useTranslation();
  const title = 'Create a Plan';
  const [companyId, setCompanyId] = useState(null);
  const [payLoad, setPayLoad] = useState({});
  const [error, setError] = useState({});
  const [errorMessage, setErrorMessage] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    show: false,
  });
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const newPlanPresenter = new NewPlanPresenter();
  const formFields = newPlanPresenter.getFormFields();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const customFooterStyles = {
    container: styles.footer_custom_style,
  };
  const customHeaderStyles = {
    headerLine: styles.header_custom_style,
  };

  useEffect(() => {
    const companyId = searchParams.get('companyId');
    const companyName = searchParams.get('companyName');
    setCompanyId(companyId);
    if (!companyId || !companyName) {
      navigate('/companylist');
    }
  }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = () => {
    let isValid = null;
    for (let data of formFields) {
      let instantValidationsChecked = handleValidations(
        data.instantValidations,
        data.code,
        payLoad[data.code],
      );
      if (instantValidationsChecked) {
        let submitValidationsChecked = handleValidations(
          data.submitValidations,
          data.code,
          payLoad[data.code],
        );
        if (submitValidationsChecked && isValid !== false) isValid = true;
        else {
          isValid = false;
        }
      } else isValid = false;
    }
    if (isValid) createNewPlan();
  };

  const getElementProps = (data) => {
    let updatedProps = {
      additionalStyles: {
        container: styles.text_additional_style,
      },
      required:
        (data.submitValidations &&
          data.submitValidations.some((validation) => validation.type === 'required')) ||
        (data.instantValidations &&
          data.instantValidations.some((validation) => validation.type === 'required')),
      hint: data.hint,
      inputType: data.inputType,
      placeholder: data.placeholder,
      defaultValue: data.defaultValue,
      error: error[data.code],
      errorMessage: errorMessage[data.code],
    };
    return updatedProps;
  };

  const handleValidations = (validations, code, validateValue) => {
    let isValid, message;
    if (validations) {
      for (let validation of validations) {
        [isValid, message] = [
          ...validationService.validate(validation.type, validateValue, validation.attributes),
        ];
        if (!isValid) {
          setError((prevErrorObj) => ({ ...prevErrorObj, [code]: true }));
          setErrorMessage((prevErrorMessageObj) => ({ ...prevErrorMessageObj, [code]: message }));
          break;
        } else {
          setError((prevErrorObj) => ({ ...prevErrorObj, [code]: false }));
          setErrorMessage((prevErrorMessageObj) => ({ ...prevErrorMessageObj, [code]: message }));
        }
      }
      return isValid ? true : false;
    } else return true;
  };

  const handleChange = (data, value) => {
    setPayLoad((prevPayload) => ({ ...prevPayload, [data.code]: value }));
    if (data.instantValidations) {
      handleValidations(data.instantValidations, data.code, value);
    } else {
      setError((prevErrorObj) => ({ ...prevErrorObj, [data.code]: false }));
      setErrorMessage((prevErrorMessageObj) => ({ ...prevErrorMessageObj, [data.code]: '' }));
    }
  };

  const getCancelButtonProps = () => {
    let metadata = {
      text: 'Cancel',
      click: cancelCreateNewPlan,
      style: styles.cancel_button_style,
    };
    return metadata;
  };

  const getSubmitButtonProps = () => {
    let metadata = {
      text: 'Submit',
      click: handleSubmit,
    };
    return metadata;
  };

  const cancelCreateNewPlan = () => {
    goToCompanyDetails();
  };

  const createNewPlan = async () => {
    const body = {
      companyId,
      description: payLoad.description,
      totalUnits: parseInt(payLoad.totalUnits),
      expirationDate: payLoad.expirationDate ? new Date(payLoad.expirationDate).toISOString() : '',
    };
    setIsLoading(true);
    setButtonDisabled(true);
    const httpResult = await httpMessenger.apiCall('POST', 'companyPlans', body);
    setIsLoading(false);
    setButtonDisabled(false);
    if (httpResult.success) {
      showDialog(true);
    } else {
      let errorMessage = [];
      httpResult.error.forEach((error) => {
        errorMessage.push(t(error.key, error.parameters));
      });
      showDialog(false, errorMessage);
    }
  };

  const showDialog = (success, errorMessage) => {
    setModalInfo({
      show: true,
      text: success ? 'The plan was created successfully.' : errorMessage,
      formActions: {
        rightButton: {
          text: 'Close',
          action: success ? goToCompanyDetails : failureModalClose,
        },
      },
      icon: success ? successTickInternal : failureIcon,
    });
  };

  const goToCompanyDetails = () => {
    navigate(`/companydetails?companyId=${companyId}`);
  };

  const failureModalClose = () => {
    setModalInfo({
      show: false,
      text: null,
      formActions: {
        rightButton: {
          text: null,
          action: null,
        },
      },
      icon: null,
    });
  };

  return (
    <PageLayout>
      <FlexView column className={styles.page_container}>
        <Header title={title} customStyles={customHeaderStyles} />
        <FlexView className={styles.newplan_content_container} column>
          <FlexView column className={styles.newplan_form_container}>
            {formFields.map((data, index) => {
              return (
                <FlexView key={index} className={styles.form_element_container}>
                  <span className={styles.form_element_label}>{data.label}</span>
                  <FlexView className={styles.form_element_type_container}>
                    <DomRender
                      elemType={data.type}
                      {...getElementProps(data)}
                      callback={(value) => handleChange(data, value)}
                    />
                  </FlexView>
                </FlexView>
              );
            })}
          </FlexView>
          <FlexView className={styles.newplan_footer_container}>
            <Button metadata={getCancelButtonProps()} />
            <Button metadata={getSubmitButtonProps()} disabled={isButtonDisabled} />
          </FlexView>
        </FlexView>
        <Footer customStyles={customFooterStyles} />
      </FlexView>
      {isLoading && <Spinner />}
      {modalInfo.show && (
        <Modal onClose={modalInfo.close} formActions={modalInfo.formActions}>
          <FlexView column className={styles.modal_content_container}>
            <img src={modalInfo.icon} className={styles.modal_content_icon} alt="modal_icon" />
            <span className={styles.modal_content_text}>{modalInfo.text}</span>
          </FlexView>
        </Modal>
      )}
    </PageLayout>
  );
};
export default NewPlan;
