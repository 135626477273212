import React from 'react';

import ToBeImplemented from '../components/ToBeImplemented';

class Products extends React.PureComponent {
  render() {
    return <ToBeImplemented />;
  }
}
export default Products;
