import PropTypes from 'prop-types';
import { useState } from 'react';
import FlexView from 'react-flexview/lib';

import styles from '../styles/Header.module.css';
import DomRender from './DomRender';

const Header = ({ title, description, customStyles, customElement, moreDetails }) => {
  const [additionalStyles] = useState(customStyles || {});

  return (
    <FlexView className={`${styles.header_container} ${additionalStyles.header_container}`} column>
      <FlexView className={styles.header_content}>
        <FlexView column>
          <span className={styles.header_title_span}>{title}</span>
          {description && <span className={styles.header_description}>{description}</span>}
        </FlexView>
        <FlexView className={styles.extra_elements_container_style}>
          {moreDetails &&
            Object.keys(moreDetails).map((elementKey, index) => {
              return (
                <FlexView className={styles.available_units_container_style} column key={index}>
                  <span className={styles.header_available_units_text}>{elementKey}</span>
                  <span>{moreDetails[elementKey]}</span>
                </FlexView>
              );
            })}
          {customElement && (
            <FlexView className={styles.custom_element_container_style}>
              <DomRender
                elemType={customElement.elemType}
                metadata={customElement.metadata}
                {...customElement.props}
              />
            </FlexView>
          )}
        </FlexView>
      </FlexView>
      <hr className={`${styles.header_separator} ${additionalStyles.headerLine}`} />
    </FlexView>
  );
};

Header.propTypes = {
  title: PropTypes.string,
};

// Specifies the default values for props:
Header.defaultProps = {
  title: 'Header',
};

export default Header;
