import { useEffect, useState } from 'react';
import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import EmptyPage from '../../components/EmptyPage';
import Button from '../../components/UI/Button';
import Table from '../../components/UI/PaginatedTable/Table';
import Switch from '../../components/UI/Switch';
import { STATE_PARAMS } from '../../consts/CommonConstants';
import DateTimeService from '../../services/DateTimeService';
import httpMessenger from '../../services/HTTPMessenger';
import localStorageService from '../../Shared/LocalStorageService';
import Spinner from '../../Shared/Spinner';
import styles from './CompanyPlans.module.css';

const columns = {
  email: {
    title: 'CREATED BY',
  },
  description: {
    title: 'DESCRIPTION',
  },
  totalUnits: {
    title: 'TOTAL <br> UNITS',
  },
  remainingUnits: {
    title: 'REMAINING <br> UNITS',
  },
  creationDate: {
    title: 'CREATION <br> DATE',
  },
  expirationDate: {
    title: 'EXPIRATION <br> DATE',
  },
};

const itemsPerPageMetadata = {
  defaultValue: {
    code: 5,
    label: '5',
  },
  options: [
    {
      code: 5,
      label: '5',
    },
    {
      code: 10,
      label: '10',
    },
    {
      code: 15,
      label: '15',
    },
  ],
  additionalStyles: {
    container: styles.items_per_page_dropdown_additional_style,
    label: styles.items_per_page_dropdown_label_additional_style,
  },
};

const CompanyPlans = ({ Id, cName }) => {
  const { t } = useTranslation();
  const [companyId, setCompanyId] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [dataLength, setDataLength] = useState(null);
  const [pageSize, setPageSize] = useState(5); //No.of rows per page
  const [planList, setPlanList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [emptyMessage, setEmptyMessage] = useState('');
  const [showExpiredPlans, setShowExpiredPlans] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dateTime = new DateTimeService();

  const tableCustomStyles = {
    tableCell: styles.tableCell,
    tableHeader: styles.tableHeader,
  };

  const localStorageStateParams =
    JSON.parse(localStorageService.getLocalStorageValue(STATE_PARAMS)) || {};

  useEffect(() => {
    const companyId = Id || localStorageStateParams.companyId;
    const companyName = cName || localStorageStateParams.companyName;
    setCompanyId(companyId);
    setCompanyName(companyName);
    localStorageService.setLocalStorageValue(
      STATE_PARAMS,
      JSON.stringify(Object.assign({}, { companyId, companyName })),
    );
    loadPlans(1, true, showExpiredPlans);
  }, [pageSize, searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      localStorageService.deleteLocalStorageValue(STATE_PARAMS);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadPlans = async (pageNumber, shouldResetData, expired) => {
    setIsLoading(true);
    const httpResult = await httpMessenger.apiCall(
      'GET',
      'companyPlans',
      { expired },
      null,
      Id || localStorageStateParams.companyId,
    );
    setIsLoading(false);
    if (httpResult.success) {
      let plans = [];
      const dataLen = httpResult.result.length;
      setDataLength(dataLen);
      if (dataLen > 0) {
        httpResult.result.forEach((plan) => {
          plans.push({
            description: plan.description,
            email: plan.email,
            totalUnits: plan.totalUnits,
            remainingUnits: plan.remainingUnits,
            creationDate: dateTime.toLocaleDateString(plan.creationDate),
            expirationDate: dateTime.toLocaleDateString(plan.expirationDate),
          });
        });
        let planListCopy = [...planList];
        if (planListCopy.length === 0 || shouldResetData) {
          planListCopy = new Array(dataLen);
          planListCopy.fill({}, 0);
        }
        const startIndex = (pageNumber - 1) * pageSize;
        planListCopy.splice(startIndex, plans.length, ...plans);
        setPlanList(planListCopy);
      } else {
        setEmptyMessage(
          'You still have no plans for the company. Start off by creating a new plan',
        );
      }
    } else {
      let errorMessage = [];
      httpResult.error.forEach((error) => {
        errorMessage.push(t(error.key, error.parameters));
      });
      setEmptyMessage(errorMessage.join(' '));
    }
  };

  const setPage = async (pageNumber) => {
    const startIndexOfCurrentPage = (pageNumber - 1) * pageSize;
    if (Object.keys(planList[startIndexOfCurrentPage]).length === 0) {
      loadPlans(pageNumber);
    }
  };

  const resetItemsPerPage = (newPageSize) => {
    setPageSize(newPageSize);
  };

  const getItemsPerPageData = () => {
    const metadata = itemsPerPageMetadata;
    const callback = resetItemsPerPage;
    return { metadata, callback };
  };

  const goToAddNewPlan = () => {
    navigate(`/newplan?companyId=${companyId}&companyName=${companyName}`);
  };

  const toggleShowExpiredPlans = async () => {
    let valueToSet = !showExpiredPlans;
    await loadPlans(1, true, valueToSet);
    setShowExpiredPlans(valueToSet);
  };

  const getHeaderActionButtonConfig = () => {
    return {
      text: '+ Add a new plan',
      click: goToAddNewPlan,
      style: styles.custom_header_button_style,
    };
  };

  return (
    <FlexView className={styles.container} column>
      <FlexView>
        <>
          <span className={styles.expired_plans_label}>EXPIRED PLANS</span>
          <Switch checked={showExpiredPlans} callback={toggleShowExpiredPlans} />
        </>
        <Button metadata={getHeaderActionButtonConfig()} />
      </FlexView>
      <FlexView className={styles.table_container} column>
        <FlexView className={styles.table_container_2} column>
          {dataLength > 0 && (
            <Table
              data={planList}
              dataLength={dataLength}
              customstyles={tableCustomStyles}
              columns={columns}
              rowsPerPage={pageSize}
              alwaysPaginated={true}
              itemsPerPageData={getItemsPerPageData()}
              callback={setPage}
            />
          )}
        </FlexView>
        {isLoading && <Spinner />}
        {!isLoading && dataLength <= 0 && <EmptyPage message={emptyMessage} />}
      </FlexView>
    </FlexView>
  );
};
export default CompanyPlans;
