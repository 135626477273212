import clsx from 'clsx';
import { useContext } from 'react';

import { UNDEFINED } from '../../consts/CommonConstants';
import ThemeContext from '../../contexts/ThemeContext';
import ConditionalWrapper from '../HOCs/ConditionalWrapper';
import styles from './Switch.module.css';
import CustomTooltip from './Tooltip';

const Switch = (props) => {
  const { theme } = useContext(ThemeContext);
  const handleChange = () => {
    if (props.callback !== UNDEFINED) {
      props.callback(!props.checked);
    }
  };

  return (
    <ConditionalWrapper
      condition={props.tooltipText}
      wrapper={(children) => <CustomTooltip title={props.tooltipText}>{children}</CustomTooltip>}
    >
      <label className={styles.switch} onClick={(e) => handleChange()}>
        <input type="checkbox" checked={props.checked} readOnly />
        <span className={clsx(styles.slider, styles.round, styles['theme_' + theme])}></span>
      </label>
    </ConditionalWrapper>
  );
};

export default Switch;
