import React, { useState } from 'react';
import FlexView from 'react-flexview/lib';

import useTable from '../../../hooks/useTable';
import ArrowDown from '../../../images/arrow_down.svg';
import ArrowUp from '../../../images/arrow_up.svg';
import parseHTML from '../../../services/HTMLParser';
import DomRender from '../../DomRender';
import Checkbox from '../Checkbox';
import styles from './Table.module.css';
import TableFooter from './TableFooter';

const Table = ({
  data,
  dataLength,
  columns,
  alwaysPaginated,
  rowsPerPage,
  callback,
  customstyles,
  itemsPerPageData,
  numberOfitemsSelectedForBulkActions,
  toggleBulkActionSelection,
  detailedViewExists,
}) => {
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(data, dataLength, page, rowsPerPage);
  const [showDetailedView, setShowDetailedView] = useState(
    detailedViewExists && data ? { [data[0].id]: true } : {},
  );
  const customStyles = customstyles || {};

  const setCurrentPage = async (value, dontInvokeCallback) => {
    setPage(value);
    /* incase of changing rows per page, its good to give control
        to the calling component to take care of changed parameters
        and avoid duplicate calls to the same callback via 2 different paths.
        Hence we specify dontInvokeCallback{true} when rows per page is changed. */
    if (callback && !dontInvokeCallback) callback(value);
  };

  const getItem = (element, column, key) => {
    if (key === 'actions') {
      if (element[key] && element[key].length > 0) {
        return (
          <FlexView className={styles.table_row_actions_container}>
            {element[key].map((action, index) => (
              <DomRender
                elemType={action.elemType}
                key={'action_' + index}
                metadata={action.metadata}
              />
            ))}
          </FlexView>
        );
      } else return null;
    } else if (column.customElement) {
      /* To render custom elements, pass it in the following format,
                element = {
                    elemType: CustomElementToRender,
                    props: {...props} // to be passed to custom component
                }
            */
      if (element[key])
        return React.createElement(element[key].elemType, { ...element[key].props });
      else return null;
    } else {
      return element[key];
    }
  };

  const toggleSetShowDetailedView = (event, rowId) => {
    event.preventDefault();
    event.stopPropagation();
    if (!showDetailedView[rowId])
      setShowDetailedView(Object.assign({}, showDetailedView, { [rowId]: true }));
    else
      setShowDetailedView(
        Object.assign({}, showDetailedView, { [rowId]: !showDetailedView[rowId] }),
      );
  };

  const headerBulkActionCheckboxHandler = (valueToSet, isClicked) => {
    toggleBulkActionSelection(page, valueToSet, isClicked);
  };

  const getColumnHeader = (key) => {
    if (columns[key].renderCheckbox)
      return (
        <Checkbox
          checked={numberOfitemsSelectedForBulkActions > 0}
          callback={(valueToSet, isClicked) =>
            headerBulkActionCheckboxHandler(valueToSet, isClicked)
          }
        />
      );
    else return parseHTML(columns[key].title);
  };

  return (
    <>
      {dataLength > 0 && (
        <>
          <table
            className={`${styles.table} ${
              range && range.length === 1 && !alwaysPaginated && styles.table_without_pagination
            }`}
          >
            <thead>
              <tr>
                {detailedViewExists && <th className={styles.tableHeader}></th>}
                {Object.keys(columns).map((key, index) => {
                  return (
                    <th
                      key={index}
                      className={`${styles.tableHeader} ${columns[key].additionalStyle}`}
                    >
                      {getColumnHeader(key)}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className={styles.tbody}>
              {slice.map((el, index) => (
                <>
                  <tr
                    key={el.id + '_' + index}
                    onClick={(event) => toggleSetShowDetailedView(event, el.id)}
                    className={detailedViewExists ? styles.parent_row_if_details_exists : null}
                  >
                    {detailedViewExists && (
                      <td className={styles.tableCell}>
                        <img src={showDetailedView[el.id] ? ArrowUp : ArrowDown} />
                      </td>
                    )}
                    {Object.keys(columns).map((key, index) => {
                      return (
                        <td
                          key={index}
                          className={`${styles.tableCell} ${customStyles.tableCell} ${columns[key].additionalStyle}`}
                        >
                          {getItem(el, columns[key], key)}
                        </td>
                      );
                    })}
                  </tr>
                  {detailedViewExists && showDetailedView[el.id] && (
                    <tr>
                      <td
                        className={`${styles.tableCell} ${customStyles.tableCell} ${styles.row_detailed_view}`}
                        colSpan={Object.keys(columns).length + 1}
                      >
                        {/* columns.length + 1 because the first column (arrow up or down) is not part of the "columns" variable.
                        so the number of columns is always going to be 1 more than the length of the columns array. */}
                        {el.detailedview}
                      </td>
                    </tr>
                  )}
                </>
              ))}
            </tbody>
          </table>
          {((range && range.length > 1) || alwaysPaginated) && (
            <TableFooter
              range={range}
              slice={slice}
              setPage={setCurrentPage}
              page={page}
              itemsPerPageData={itemsPerPageData}
            />
          )}
        </>
      )}
    </>
  );
};

export default Table;
