import Button from '../components/UI/Button';
import Chip from '../components/UI/Chip';
import Dropdown from '../components/UI/Dropdown';
import IconButton from '../components/UI/IconButton';
import Input from '../components/UI/Input';
import Label from '../components/UI/Label';
import Link from '../components/UI/Link';
import RadioGroup from '../components/UI/RadioGroup';
import TextArea from '../components/UI/TextArea';

export const ElementMap = {
  button: Button,
  'icon-button': IconButton,
  link: Link,
  label: Label,
  dropdown: Dropdown,
  radiogroup: RadioGroup,
  text: Input,
  textarea: TextArea,
  chip: Chip,
};
