import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useEffect, useState } from 'react';
import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';

import { UNDEFINED } from '../../consts/CommonConstants';
import helpIcon from '../../images/i-icon.svg';
import { getCSSValue } from '../../services/CSS_JS';
import parseHTML from '../../services/HTMLParser';
import CustomTooltip from '../UI/Tooltip';
import styles from './Dropdown.module.css';

export default function Dropdown({
  hint,
  options,
  multiselect,
  defaultValue,
  description,
  descriptionKeys = [],
  error,
  errorMessage,
  errorMessageParams = {},
  callback,
  required,
  additionalStyles = {},
}) {
  const [value, setValue] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (defaultValue !== UNDEFINED) {
      handleChange(null, defaultValue);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const labelStyles = {
    '& .MuiFormLabel-root': {
      color: `${getCSSValue('--zortify-black-30')}`,
      fontSize: '1vw',
      paddingLeft: '0.5vw',
      fontFamily: 'Poppins',
    },
    '&.Mui-error': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid var(--zortify-error-100) !important',
      },
    },
  };

  const handleChange = (e, value) => {
    let selectedValue = e !== null ? e.target.value : value;
    if (e === null && typeof value === 'object') {
      if (value) {
        selectedValue = value.code;
      }
    }
    setValue(selectedValue);
    if (callback !== UNDEFINED) callback(selectedValue);
  };

  const getDescription = () => {
    let finalDescription = description;
    descriptionKeys.forEach((key) => {
      finalDescription = finalDescription.replace('PLACE_HOLDER', t(key));
    });
    return parseHTML(finalDescription);
  };

  return (
    <FlexView className={additionalStyles?.container} column>
      <FlexView>
        <Select
          sx={labelStyles}
          multiple={multiselect}
          value={value}
          onChange={handleChange}
          size="small"
          className={`${styles.dropdown_label} ${additionalStyles?.label}`}
          error={error}
        >
          {options &&
            options.map((option, index) => (
              <MenuItem key={index} value={typeof option === 'object' ? option.code : option}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
        {description && (
          <CustomTooltip title={getDescription()} placement="right">
            <img src={helpIcon} className={styles.dropdown_description_tooltip} />
          </CustomTooltip>
        )}
        {/* {required && <span className={styles.required}>*</span>} */}
      </FlexView>
      {(error || hint) && (
        <FlexView className={styles.dropdown_helper_text_container} column>
          {error && <span className={styles.dropdown_error_message_span}>{t(errorMessage)}</span>}
          {hint && (
            <FlexView>
              <span className={styles.dropdown_helper_text}>{hint}</span>
            </FlexView>
          )}
        </FlexView>
      )}
    </FlexView>
  );
}
