import FlexView from 'react-flexview/lib';

import styles from './LegalDisclosure.module.css';

const Vat = () => {
  return (
    <FlexView className={styles.general_container} column>
      <span>TVA N° LU31046634</span>
    </FlexView>
  );
};

export default Vat;
