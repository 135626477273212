import React from 'react';

import { THEMES } from '../consts/CommonConstants';

const ThemeContext = React.createContext({
  theme: THEMES.EXTERNAL,
  setTheme: () => {},
});

export default ThemeContext;
