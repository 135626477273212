import { createReducer } from '@reduxjs/toolkit';

import { resetInvitationsContext, setInvitationsContext } from '../actions/InvitationsActions';

const initialState = {};

export const invitationsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setInvitationsContext, (state, action) => {
      state.context = action.payload;
    })
    .addCase(resetInvitationsContext, (state) => {
      state.context = undefined;
    });
});
