/**
 * This service provides the API URL paths to call different APIs in specific environments.
 * Also, it can be used to return multiple other paths such as storage bucket paths etc.
 */

const apiList = Object.freeze({
  planConsumption: {
    value: '/dashboard/v3/consumption',
  },
  products: {
    value: '/dashboard/v3/product',
  },
  invitation: {
    value: '/dashboard/v3/invitation',
  },
  company: {
    value: '/dashboard/v3/sales/company',
  },
  surveyMapping: {
    value: '/dashboard/v3/sales/company/PLACE_HOLDER/survey-mapping', //{{companyId}}
  },
  companyUser: {
    value: '/dashboard/v3/sales/company/PLACE_HOLDER/users', //{{companyId}}
  },
  passwordReset: {
    value: '/dashboard/v3/admin/user/PLACE_HOLDER/password-reset',
  },
  createCompany: {
    value: '/dashboard/v3/admin/company',
  },
  companyPlans: {
    value: '/dashboard/v3/sales/plan',
  },
  user: {
    value: '/dashboard/v3/sales/user',
  },
  adminUser: {
    value: '/dashboard/v3/admin/user',
  },
  invitationUpdate: {
    value: '/dashboard/v3/invitation/PLACE_HOLDER', //{{invitationID}}
  },
  sendDCRequest: {
    value: '/dashboard/v3/invitation/PLACE_HOLDER/coaching', //{{invitationID}}
  },
  updateUserLocale: {
    value: '/dashboard/v3/user/preferences/locale/PLACE_HOLDER', //{{localeCode}}
  },
});

class ApiService {
  /**
   * Method to return the corresponding api of the given key
   * @param {PATH} key The key of the api to be returned
   * @returns {String}
   */
  ApiService() {
    this.path = null;
  }

  get = (key, placeholderValues) => {
    if (!this.path) {
      this.path = process.env.REACT_APP_API_URL;
    }

    try {
      let urlSuffix = apiList[key]['value'];
      let urlPrefix = this.path;
      if (placeholderValues) {
        placeholderValues.forEach((placeholderValue) => {
          urlSuffix = urlSuffix.replace('PLACE_HOLDER', placeholderValue);
        });
      }
      let res = urlPrefix + urlSuffix;
      return res;
    } catch (err) {
      console.error('Error in determining url for key ' + key + ' ' + err);
      return null;
    }
  };
}

export let apiService = new ApiService();
