import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from '../styles/Footer.module.css';

const Footer = (props) => {
  const customStyles = props.customStyles || {};
  const { t } = useTranslation();
  return (
    <FlexView className={`${styles.footer_container} ${customStyles.container}`} column>
      <hr className={styles.footer_line} />
      <span className={styles.footer_span}>
        <Link to="/privacypolicy" target="_blank" className={styles.footer_link}>
          Zortify {t('privacy.title')}
        </Link>
        &nbsp;|&nbsp;
        <Link to="/legaldisclosure" target="_blank" className={styles.footer_link}>
          {t('legal-disclosure.title')}
        </Link>
      </span>
    </FlexView>
  );
};
export default Footer;
