import { useEffect, useState } from 'react';
import FlexView from 'react-flexview';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import EmptyPage from '../../components/EmptyPage';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import PageLayout from '../../components/PageLayout';
import { UNDEFINED } from '../../consts/CommonConstants';
import httpMessenger from '../../services/HTTPMessenger';
import CompanyPlans from '../CompanyPlans/CompanyPlans';
import SurveyMapping from '../SurveyMapping/SurveyMapping';
import CompanyUsers from '../Users/CompanyUsers/CompanyUsers';
import styles from './CompanyDetails.module.css';

const companyDetailsTabs = [
  {
    title: 'Users',
  },
  {
    title: 'Mapping',
  },
  {
    title: 'Plans',
  },
];
function CompanyDetails() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [companyDetails, setCompanyDetails] = useState(
    useSelector(
      (state) =>
        state.companyList.context &&
        state.companyList.context.find((company) => company.id === searchParams.get('companyId')),
    ) || {},
  );
  const defaultActiveIndex = 0;
  const [activeTabIndex, setActiveTabIndex] = useState(defaultActiveIndex);
  const [emptyMessage, setEmptyMessage] = useState('');

  const customHeaderStyles = {
    headerLine: styles.header_custom_style,
    header_container: styles.header_container_custom_style,
  };

  const getMoreHeaderDetails = () => {
    const hubspotId = companyDetails.invoicingCompanyHsId;
    const invoicingCompanyName = companyDetails.invoicingCompanyName;
    return {
      'HUBSPOT ID': hubspotId,
      'INVOICE COMPANY': invoicingCompanyName,
    };
  };

  const navigateToTab = (index) => {
    setActiveTabIndex(index);
  };

  useEffect(() => {
    const loadNewCompanyDetails = async () => {
      if (companyDetails && Object.keys(companyDetails).length === 0) {
        let httpResult = await httpMessenger.apiCall(
          'GET',
          'company',
          {},
          null,
          searchParams.get('companyId'),
        );
        if (httpResult.success) {
          let details = {
            id: httpResult.result.id,
            companyName: httpResult.result.name,
            invoicingCompanyName: httpResult.result.invoicingCompanyName,
            invoicingCompanyHsId: httpResult.result.invoicingCompanyHsId,
          };
          setCompanyDetails(details || {});
        } else {
          let errorMessage = [];
          httpResult.error.forEach((error) => {
            errorMessage.push(t(error.key, error.parameters));
          });
          setEmptyMessage(errorMessage.join(' '));
        }
      }
    };

    loadNewCompanyDetails();
    /* incase a new company is created and immediatedly user is navigated to this page,
    new api call should be made to retrieve the company details
    since it is not yet stored in the redux store */
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PageLayout>
      <FlexView column>
        <Header
          title={companyDetails.companyName}
          customStyles={customHeaderStyles}
          moreDetails={getMoreHeaderDetails()}
        />
        <FlexView className={styles.details_container} column>
          <FlexView>
            {companyDetailsTabs.map((tab, index) => {
              return (
                <div
                  key={index}
                  className={`${styles.inactive_tab} ${
                    activeTabIndex === index ? styles.active_tab : null
                  }`}
                  onClick={() => navigateToTab(index)}
                >
                  <span>{tab.title}</span>
                </div>
              );
            })}
          </FlexView>
          {activeTabIndex === 0 && companyDetails.id && (
            <CompanyUsers Id={companyDetails.id} cName={companyDetails.companyName} />
          )}
          {activeTabIndex === 1 && companyDetails.id && <SurveyMapping Id={companyDetails.id} />}
          {activeTabIndex === 2 && companyDetails.id && (
            <CompanyPlans Id={companyDetails.id} cName={companyDetails.companyName} />
          )}
        </FlexView>
        {!companyDetails && <EmptyPage message={emptyMessage} />}
        <Footer />
      </FlexView>
    </PageLayout>
  );
}

export default CompanyDetails;
