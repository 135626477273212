import { useEffect, useState } from 'react';
import FlexView from 'react-flexview';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import EmptyPage from '../../components/EmptyPage';
import Header from '../../components/Header';
import PageLayout from '../../components/PageLayout';
import Chip from '../../components/UI/Chip';
import httpMessenger from '../../services/HTTPMessenger';
import CompanyRoleMapper from '../Users/CompanyRoleMapper';
import styles from './UserDetails.module.css';

function UserDetails() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [userDetails, setUserDetails] = useState(
    useSelector(
      (state) =>
        state.userList.context &&
        state.userList.context.find((user) => user.id === searchParams.get('userId')),
    ) || {},
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [userDetailsDisplay, setUserDetailsDisplay] = useState({
    firstName: {
      label: 'First Name',
      value: userDetails.firstName,
    },
    lastName: {
      label: 'Last Name',
      value: userDetails.lastName,
    },
    email: {
      label: 'Email',
      value: userDetails.email,
    },
    memberships: {
      label: 'Companies',
      value: userDetails.memberships || {},
    },
  });
  const sections = [['firstName', 'lastName', 'email'], ['memberships'], ['expirationDate']];
  const companyRoleMapper = new CompanyRoleMapper();

  useEffect(() => {
    const loadNewUserDetails = async () => {
      if (userDetails && Object.keys(userDetails).length === 0) {
        const httpResult = await httpMessenger.apiCall(
          'GET',
          'user',
          null,
          null,
          searchParams.get('userId'),
        );
        if (httpResult.success) {
          let details = {
            firstName: httpResult.result.firstName,
            lastName: httpResult.result.lastName,
            email: httpResult.result.email,
            memberships: companyRoleMapper.getCompanyRoleMapping(httpResult.result.memberships),
          };
          setUserDetails(details || {});
          setUserDetailsDisplay({
            firstName: {
              label: 'First Name',
              value: details.firstName,
            },
            lastName: {
              label: 'Last Name',
              value: details.lastName,
            },
            email: {
              label: 'Email',
              value: details.email,
            },
            memberships: {
              label: 'Companies',
              value: details.memberships || {},
            },
          });
        } else {
          let errorMessage = [];
          httpResult.error.forEach((error) => {
            errorMessage.push(t(error.key, error.parameters));
          });
          setErrorMessage(errorMessage.join(' '));
        }
      }
    };

    loadNewUserDetails();
    /* incase a new company is created and immediatedly user is navigated to this page,
    new api call should be made to retrieve the company details
    since it is not yet stored in the redux store */
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PageLayout>
      <Header title="Users details" />
      {!errorMessage && (
        <FlexView className={styles.user_details_container} column>
          <FlexView className={styles.sec_0_container}>
            {sections[0].map((label, index) => {
              return (
                <SimpleLabelValueComponent
                  key={index}
                  label={userDetailsDisplay[label].label}
                  value={userDetailsDisplay[label].value}
                />
              );
            })}
          </FlexView>

          <FlexView className={styles.sec_1_container} column>
            <span className={styles.company_label}>{userDetailsDisplay.memberships.label}</span>
            {Object.keys(userDetailsDisplay.memberships.value).map((company, index) => {
              return (
                <LabelChipsComponent
                  key={index}
                  companyId={company}
                  label={userDetailsDisplay.memberships.value[company].companyName}
                  values={userDetailsDisplay.memberships.value[company].roles}
                />
              );
            })}
          </FlexView>
        </FlexView>
      )}
      {errorMessage && <EmptyPage message={errorMessage} />}
    </PageLayout>
  );
}

function SimpleLabelValueComponent({ label, value }) {
  return (
    <FlexView column className={styles.simple_container}>
      <span className={styles.simple_label}>{label}</span>
      <span className={styles.simple_value}>{value}</span>
    </FlexView>
  );
}

function LabelChipsComponent({ companyId, label, values }) {
  const navigate = useNavigate();
  const style = {
    container: styles.chip_container,
    span: styles.chip_span,
  };

  const goToCompanyDetails = () => {
    navigate(`/companydetails?companyId=${companyId}`);
  };

  return (
    <FlexView column className={styles.simple_container}>
      <span className={styles.chip_label} onClick={goToCompanyDetails}>
        {label}
      </span>
      <FlexView>
        {values.map((value, index) => {
          return <Chip key={index} metadata={{ text: value, style }} />;
        })}
      </FlexView>
    </FlexView>
  );
}

export default UserDetails;
