import FlexView from 'react-flexview/lib';
import { Trans, useTranslation } from 'react-i18next';

import styles from './PrivacyPolicy.module.css';

const DataSubjectRights = () => {
  let { t } = useTranslation();
  return (
    <FlexView className={styles.general_container} column>
      <span>{t('privacy.data-subject-rights.text1')}</span>
      <br />
      <span>{t('privacy.data-subject-rights.text2')}</span>
      <ul>
        <li>{t('privacy.data-subject-rights.text3')}</li>
        <li>{t('privacy.data-subject-rights.text4')}</li>
        <li>{t('privacy.data-subject-rights.text5')}</li>
        <li>{t('privacy.data-subject-rights.text6')}</li>
        <li>{t('privacy.data-subject-rights.text7')}</li>
        <li>{t('privacy.data-subject-rights.text8')}</li>
      </ul>

      <br />
      <span>{t('privacy.data-subject-rights.text9')}</span>
      <br />
      <span>{t('privacy.data-subject-rights.text10')}</span>
      <br />
      <span>{t('privacy.data-subject-rights.text11')}</span>
      <br />
      <span>
        <Trans
          i18nKey="privacy.data-subject-rights.text12"
          components={{
            1: (
              <a href="mailto:privacy@zortify.com" className={styles.general_info_href}>
                {' '}
              </a>
            ),
          }}
        />
        {t('privacy.data-subject-rights.text13')}
      </span>
    </FlexView>
  );
};
export default DataSubjectRights;
