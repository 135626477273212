import FlexView from 'react-flexview';
import { useTranslation } from 'react-i18next';

import styles from './ConfigDisplay.module.css';

export default function ConfigDisplay({ config }) {
  const { t } = useTranslation();
  return (
    <FlexView id="inner" className={styles.container}>
      {config.map((configEntry, index) => {
        return (
          <FlexView key={index} className={styles.config_container} column>
            <span className={styles.config_title}>{t(configEntry.label)}</span>
            <span className={styles.config_value}>{t(configEntry.value)}</span>
          </FlexView>
        );
      })}
    </FlexView>
  );
}
