import { useEffect, useState } from 'react';
import FlexView from 'react-flexview';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import DomRender from '../../components/DomRender';
import Modal from '../../components/UI/Modal';
import Snackbar from '../../components/UI/Snackbar';
import { calculateRange } from '../../hooks/useTable';
import failureIcon from '../../images/important_note.svg';
import successTickInternal from '../../images/success_tick_internal.svg';
import httpMessenger from '../../services/HTTPMessenger';
import Spinner from '../../Shared/Spinner';
import validationService from '../../Shared/ValidationService';
import styles from './CreateUser.module.css';

function CreateUser(props) {
  const { t } = useTranslation();
  const [payLoad, setPayLoad] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();
  const [formFields, setFormFields] = useState([
    {
      label: 'User name',
      code: 'firstName',
      type: 'text',
      placeholder: 'Enter first name',
      style: {
        container: styles.form_field_additional_style,
      },
      instantValidations: [
        {
          type: 'required',
          attributes: {},
        },
      ],
    },
    {
      label: '',
      code: 'lastName',
      type: 'text',
      placeholder: 'Enter last name',
      style: {
        container: styles.form_field_additional_style,
      },
      instantValidations: [
        {
          type: 'required',
          attributes: {},
        },
      ],
    },
    {
      label: 'Email address',
      code: 'email',
      type: 'text',
      placeholder: 'Enter email address',
      style: {
        container: styles.form_field_additional_style,
      },
      instantValidations: [
        {
          type: 'required',
          attributes: {},
        },
      ],
      submitValidations: [
        {
          type: 'email',
          attributes: {},
        },
      ],
    },
    {
      label: 'Company name',
      code: 'companyName',
      type: 'dropdown',
      hint: 'Select a company user has access to',
      placeholder: '-Select-',
      //options dynamically added after getcompanies() call.
      style: {
        container: styles.dropdown_additional_container_style,
        label: styles.dropdown_additional_label_style,
      },
      submitValidations: [
        {
          type: 'required',
          attributes: {},
        },
      ],
    },
    {
      label: 'Role',
      code: 'role',
      type: 'dropdown',
      placeholder: '-Select-',
      style: {
        container: styles.dropdown_additional_container_style,
        label: styles.dropdown_additional_label_style,
      },
      defaultValue: {
        code: 'USER',
        label: 'User',
      },
      options: [
        {
          code: 'USER',
          label: 'User',
        },
        {
          code: 'SALES',
          label: 'Sales',
        },
        {
          code: 'ADMIN',
          label: 'Admin',
        },
      ],
      submitValidations: [
        {
          type: 'required',
          attributes: {},
        },
      ],
    },
  ]);
  const [error, setError] = useState({});
  const [errorMessage, setErrorMessage] = useState({});
  const [snackBar, setSnackBar] = useState({
    showSnackbar: false,
    message: '',
  });
  const [companyList, setCompanyList] = useState([]);

  const getCompanies = async (pageNumber, pageSize, list) => {
    let companyList = list ? [...list] : [];
    let httpResult = await httpMessenger.apiCall('GET', 'company', { pageNumber, pageSize });
    if (httpResult.success) {
      let totalElements = httpResult.result.totalElements;
      let numberOfPages = calculateRange(totalElements, pageSize);
      if (totalElements > 0) {
        httpResult.result.content.forEach((content) => {
          companyList.push({
            code: content.name,
            label: content.name,
            id: content.id,
          });
        });
      }
      if (numberOfPages.length > pageNumber)
        await getCompanies(pageNumber + 1, pageSize, companyList);
      else {
        setFormFields((formFields) => {
          let newFormFields = [...formFields];
          newFormFields[3].options = [...companyList];
          return newFormFields;
        });
        setCompanyList(companyList);
      }
    } else {
      setError(true);
      let message = [];
      httpResult.error.forEach((error) => {
        message.push(t(error.key, error.parameters));
      });
      setErrorMessage(message.join(' '));
    }
  };
  useEffect(() => {
    const loadCompanies = async () => {
      await getCompanies(1, 10); //page 1, pageSize 10
      if (props.cName) {
        setFormFields((formFields) => {
          let newFormFields = [...formFields];
          newFormFields.splice(3, 1);
          return newFormFields;
        });
      }
    };
    loadCompanies();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = () => {
    let isValid;
    for (let data of formFields) {
      let instantValidationsChecked = handleValidations(
        data.instantValidations,
        data.code,
        payLoad[data.code],
      );
      if (instantValidationsChecked) {
        let submitValidationsChecked = handleValidations(
          data.submitValidations,
          data.code,
          payLoad[data.code],
        );
        if (submitValidationsChecked && isValid !== false) isValid = true;
        else {
          isValid = false;
        }
      } else isValid = false;
    }
    if (isValid) createUser();
  };

  const closeDialog = (resultIfSuccess) => {
    setPayLoad({});
    setError({});
    setErrorMessage({});
    modalClose();
    props.modalClose();
    if (resultIfSuccess !== undefined) {
      const result = resultIfSuccess;
      if (result.id) {
        if (props.callback) props.callback();
        else {
          let selectedCompany = companyList.find((company) => company.code === payLoad.companyName);
          if (selectedCompany) navigate(`/companydetails?companyId=${selectedCompany.id}`);
        }
      }
    }
  };

  const handleSnackbarClose = () => {
    setSnackBar({
      showSnackbar: false,
      message: '',
    });
  };

  const getElementProps = (data) => {
    let updatedProps = {};
    try {
      switch (data.type) {
        case 'text':
          updatedProps.placeholder = data.placeholder;
          updatedProps.additionalStyles = { ...data.style };
          updatedProps.required =
            (data.submitValidations &&
              data.submitValidations.some((validation) => validation.type === 'required')) ||
            (data.instantValidations &&
              data.instantValidations.some((validation) => validation.type === 'required'));
          updatedProps.error = error[data.code];
          updatedProps.errorMessage = errorMessage[data.code];
          updatedProps.defaultValue = data.defaultValue || payLoad[data.code];
          break;
        case 'dropdown':
          updatedProps.hint = data.hint;
          updatedProps.placeholder = data.placeholder;
          updatedProps.options = data.options;
          updatedProps.defaultValue = payLoad[data.code] || data.defaultValue;
          updatedProps.additionalStyles = { ...data.style };
          updatedProps.required =
            (data.submitValidations &&
              data.submitValidations.some((validation) => validation.type === 'required')) ||
            (data.instantValidations &&
              data.instantValidations.some((validation) => validation.type === 'required'));
          updatedProps.error = error[data.code];
          updatedProps.errorMessage = errorMessage[data.code];
          break;
      }
    } catch (err) {
      console.log(err);
      return updatedProps;
    }
    return updatedProps;
  };

  const createUser = async () => {
    const body = {
      firstName: payLoad.firstName,
      lastName: payLoad.lastName,
      email: payLoad.email,
      companyName: props.cName || payLoad.companyName,
      role: payLoad.role,
    };
    setisLoading(true);
    const httpResult = await httpMessenger.apiCall('POST', 'adminUser', body);
    setisLoading(false);
    if (httpResult.success) {
      showDialog(true, httpResult.result);
    } else {
      let errorMessage = [];
      httpResult.error.forEach((error) => {
        errorMessage.push(t(error.key, error.parameters));
      });
      showDialog(false, errorMessage.join(' '));
    }
  };

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
    setSnackBar({
      showSnackbar: true,
      message: 'Password copied to clipboard',
    });
  };

  const showDialog = (success, resultOrErrorMessage) => {
    setModalInfo({
      show: true,
      showMessage: true,
      userPassword: success ? resultOrErrorMessage.password : null,
      text: success
        ? 'A new user was successfully added. This is the only time you will see the new password!'
        : resultOrErrorMessage,
      formActions: success
        ? {
            leftButton: {
              text: 'Close',
              action: () => closeDialog(resultOrErrorMessage),
              style: styles.modal_cancel_button_style,
            },
            rightButton: {
              text: 'Copy the password',
              leftIcon: 'copy_internal_transparent.svg',
              action: () => copyToClipboard(resultOrErrorMessage.password),
              style: styles.modal_copy_password_submit_button_style,
            },
          }
        : {
            rightButton: {
              text: 'Close',
              action: showForm,
            },
          },
      icon: success ? successTickInternal : failureIcon,
    });
  };

  const formActions = {
    rightButton: {
      text: 'Submit',
      action: handleSubmit,
      style: styles.modal_submit_button_style,
    },
    leftButton: {
      text: 'Cancel',
      action: () => closeDialog(),
      style: styles.modal_cancel_button_style,
    },
  };

  const [modalInfo, setModalInfo] = useState({
    show: false,
    formActions,
  });

  const handleValidations = (validations, code, validateValue) => {
    let isValid, message;
    if (validations) {
      for (let validation of validations) {
        [isValid, message] = [
          ...validationService.validate(validation.type, validateValue, validation.attributes),
        ];
        if (!isValid) {
          setError((prevErrorObj) => ({ ...prevErrorObj, [code]: true }));
          setErrorMessage((prevErrorMessageObj) => ({ ...prevErrorMessageObj, [code]: message }));
          break;
        } else {
          setError((prevErrorObj) => ({ ...prevErrorObj, [code]: false }));
          setErrorMessage((prevErrorMessageObj) => ({ ...prevErrorMessageObj, [code]: message }));
        }
      }
      return isValid ? true : false;
    } else return true;
  };

  const handleChange = (data, value) => {
    setPayLoad((prevPayload) => ({ ...prevPayload, [data.code]: value }));
    if (data.instantValidations) {
      handleValidations(data.instantValidations, data.code, value);
    } else {
      setError((prevErrorObj) => ({ ...prevErrorObj, [data.code]: false }));
      setErrorMessage((prevErrorMessageObj) => ({ ...prevErrorMessageObj, [data.code]: '' }));
    }
  };

  const modalClose = () => {
    setModalInfo({
      show: false,
      showMessage: false,
    });
  };

  const showForm = () => {
    setModalInfo({
      show: props.modalOpen,
      formActions,
    });
  };

  useEffect(() => {
    setModalInfo({
      show: props.modalOpen,
      formActions,
    });
  }, [props.modalOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    /* This does not change the value of modalInfo, but updates the values inside
    the function calls handleSubmit and closeDialog to hold the latest
    values of the useState variable (payload) */
    if (modalInfo.show)
      setModalInfo((modalInfo) => ({ ...modalInfo, ...{ formActions: formActions } }));
  }, [payLoad]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {modalInfo.show && !modalInfo.showMessage && (
        <Modal
          headerText={'Add a new user'}
          onClose={() => closeDialog()}
          formActions={modalInfo.formActions}
        >
          <FlexView column className={styles.modal_content_container}>
            {formFields.map((data, index) => {
              return (
                <FlexView className={styles.form_element_container} key={index}>
                  <span className={styles.form_element_label}>{data.label}</span>
                  <FlexView className={styles.form_element_type_container}>
                    <DomRender
                      elemType={data.type}
                      {...getElementProps(data)}
                      callback={(value) => handleChange(data, value)}
                    />
                  </FlexView>
                </FlexView>
              );
            })}
          </FlexView>
        </Modal>
      )}
      {modalInfo.show && modalInfo.showMessage && (
        <Modal onClose={() => closeDialog()} formActions={modalInfo.formActions}>
          <FlexView column className={styles.message_outer_container}>
            <FlexView column className={styles.modal_message_content_container}>
              <img src={modalInfo.icon} className={styles.modal_content_icon} alt="modal_icon" />
              <span className={styles.modal_content_text}>{modalInfo.text}</span>
            </FlexView>
            {modalInfo.userPassword && (
              <FlexView className={styles.label_container}>
                <FlexView className={styles.password_label_container}>
                  <p className={`${styles.textbox} ${styles.profile_label}`}>Password</p>
                </FlexView>
                <FlexView className={styles.password_container}>
                  <p className={`${styles.textbox} ${styles.profile_value}`}>
                    {modalInfo.userPassword}
                  </p>
                </FlexView>
              </FlexView>
            )}
          </FlexView>
        </Modal>
      )}
      <Snackbar
        open={snackBar.showSnackbar}
        onClose={handleSnackbarClose}
        message={snackBar.message}
      />
      {isLoading && <Spinner />}
    </>
  );
}

export default CreateUser;
