import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';

import styles from './PrivacyPolicy.module.css';

const MarketAndIndustrialResearch = () => {
  let { t } = useTranslation();
  return (
    <FlexView className={styles.general_container} column>
      <span>
        {t('privacy.market-and-industrial-research.text1')}{' '}
        {t('privacy.market-and-industrial-research.text2')}
      </span>
      <br />
      <span>
        {t('privacy.market-and-industrial-research.text3')}{' '}
        {t('privacy.market-and-industrial-research.text4')}
      </span>
    </FlexView>
  );
};

export default MarketAndIndustrialResearch;
