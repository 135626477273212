import FlexView from 'react-flexview/lib';

import closeIcon from '../../images/close_icon.svg';
import styles from './Snackbar.module.css';

const Snackbar = (props) => {
  const { message, open, onClose } = props;

  return open ? (
    <FlexView className={styles.snackbar_container}>
      <span className={styles.snackbar_message_span}>{message}</span>
      <img className={styles.snackbar_close} onClick={onClose} src={closeIcon} alt="close_icon" />
    </FlexView>
  ) : null;
};

export default Snackbar;
