import { useEffect, useState } from 'react';
import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';

import { UNDEFINED } from '../../consts/CommonConstants';
import styles from './TextArea.module.css';

const TextArea = ({
  rows,
  readOnly,
  required,
  maxlength,
  inputType,
  defaultValue,
  placeholder,
  hint,
  error,
  errorMessage,
  errorMessageParams = {},
  callback,
  inputRef,
  additionalStyles = {},
}) => {
  const [value, setValue] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (defaultValue !== UNDEFINED) {
      handleChange(defaultValue);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getTextAreaClasses = () => {
    let classes = styles.form__field;
    if (additionalStyles.textarea) classes += ` ${additionalStyles.textarea}`;
    if (error) classes += ` ${styles.form__field_invalid}`;
    return classes;
  };

  const handleChange = (value) => {
    setValue(value);
    if (callback !== UNDEFINED) callback(value);
  };

  return (
    <FlexView className={additionalStyles.container} column>
      <FlexView>
        <textarea
          rows={rows || 8}
          ref={inputRef}
          readOnly={readOnly}
          maxLength={maxlength}
          value={value}
          type={inputType}
          onChange={(e) => handleChange(e.target.value)}
          className={getTextAreaClasses()}
          placeholder={placeholder}
        />
        {/* {required && <span className={styles.required}>*</span>} */}
      </FlexView>
      {maxlength && !error && (
        <div className={styles.character_count}>
          <span>{value ? value.length : 0}</span>
          <span>/{maxlength}</span>
        </div>
      )}
      <FlexView className={styles.textarea_helper_text_container} column>
        {error && (
          <span className={styles.textarea_error_message_span}>
            {t(errorMessage, errorMessageParams)}
          </span>
        )}
        {hint && (
          <FlexView>
            <span className={styles.textarea_helper_text}>{hint}</span>
          </FlexView>
        )}
      </FlexView>
    </FlexView>
  );
};

export default TextArea;
