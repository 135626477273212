import FlexView from 'react-flexview/lib';

import Button from '../Button';
import styles from './TableBulkActions.module.css';

const TableBulkActions = ({
  actions,
  numberOfitemsSelectedForBulkActions,
  itemsSelectedMessage,
}) => {
  return (
    <FlexView className={styles.bulk_actions_container}>
      {actions.map((action, index) => {
        return (
          <Button
            metadata={action}
            key={index}
            disabled={numberOfitemsSelectedForBulkActions <= 0}
          />
        );
      })}
      {numberOfitemsSelectedForBulkActions > 0 && (
        <span className={styles.items_selected_span}>
          {itemsSelectedMessage || `${numberOfitemsSelectedForBulkActions} item(s) selected.`}
        </span>
      )}
    </FlexView>
  );
};

export default TableBulkActions;
